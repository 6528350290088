import InputProcent from "./InputProcent.js";
import InputDouble from "./InputDouble.js";
import Period from "./Period.js";


export default function LoanLyhennykset(props) {

   


console.log("PROPS", props.data.payments);

    const header=<tr><th>Palkkakausi</th><th>Lyhennys</th></tr>;

const items = props.data.payments.map((item,index) =>


 
        <tr key={index}>
            
            

        <td>{item.period}</td> 

        <td>{item.yrityslainan_lyhennys} €</td>
        
        </tr>
        


	);


	return (<table className="lainan-lyhennykset"><thead>{header}</thead><tbody>{items}</tbody></table>);
}
