


export default function DateSelect(props) {

    const changeInputValue = (e) => {
		let name = e.target.name;
		let value = e.target.value;
        
        

            if(name=="year"){
                dateArr[0]=value;
            }
            if(name=="month"){
                if((""+value).length==1){
                    value="0"+value;
                }
                dateArr[1]=value;
            }
            if(name=="day"){
                if((""+value).length==1){
                    value="0"+value;
                }
                dateArr[2]=value;
            }
           
           
           
            props.onChange(props.name,dateArr.join("-"));
       
             
     
	};
    let dateArr=new Array();
   
    if(props.data[props.name]!=null){
        dateArr=props.data[props.name].split("-");
    }else{

        dateArr[0]=0;
        dateArr[1]=0;
        dateArr[2]=0;
    }
   // let dateArr=props.data[props.name].split("-");
  console.log("da",dateArr,props.data[props.name]);
    const year_selector=()=>{

        let years=[];
         let start= parseInt(new Date().getFullYear())+1;
        for(var i=0;i<80;i++){
            years.push(start-i);
        }
       
        const year_options=years.map((option,index) =>
            <option key={index} value={option}>{option}</option>
        );
        


        return <select disabled={(props.disable_year===true?true:false)}  onChange={(e) => {
            changeInputValue(e);
        }} name="year" value={dateArr.length==3?parseInt(dateArr[0]):0}><option value="0">--YYYY--</option>{year_options}</select>


    };
    const month_selector=()=>{

        let years=[];
        
        for(var i=0;i<12;i++){
            let n=i+1;
            years.push(n);
        }
       
        const month_options=years.map((option,index) =>
        <option key={index} value={option}>{option}</option>
        );
       
        return <select disabled={(props.disable_month===true?true:false)} onChange={(e) => {
            changeInputValue(e);
        }} name="month" value={dateArr.length==3?parseInt(dateArr[1]):0}><option value="0">--KK--</option>{month_options}</select>


    };
    const isDateAllowed=(d)=>{


        if(props.day_min && props.day_max){
            if(d<props.day_min){
                return false;
            }
            if(d>props.day_max){
                return false;
            }
        }

        return true;
    }
    const day_selector=()=>{

        let years=[];
        
        for(var i=0;i<31;i++){
            let n=i+1;

            years.push(n);
        }
       
        const day_options=years.map((option,index) =>

        <option key={index} disabled={!isDateAllowed(option)} value={option}>{option}</option>

        );
       


        return <select name="day"  onChange={(e) => {
            changeInputValue(e);
        }} value={dateArr.length==3?parseInt(dateArr[2]):0}><option value="0">--DD--</option>{day_options}</select>


    };
    const errorkey=(props.name);
    console.log(props.errors);

    const hasError=(props.errors && props.errors[errorkey]?true:false);

    if(!props.edit){
        return (<div className={"input"+(hasError?" error":"")}><label>{props.label}</label><span>{props.data[props.name]}</span></div>);
    }
	return (<div className={"input"+(hasError?" error":"")}><label>{props.label}</label>{day_selector()} . {month_selector()} . {(year_selector())}
     {(hasError?<p className="error-text">{props.errors[errorkey]}</p>:null)}
    </div>);
}
