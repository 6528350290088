


export default function InputText(props) {

    const changeInputValue = (e) => {
		let name = e.target.name;
		let value = e.target.value;
      
        props.onChange(name,value,props.index);
     
	};
    const onBlur=(e)=>{

        if(props.onBlur){
          let name = e.target.name;
          let value = e.target.value;
          props.onBlur(name,(value),props.index,props.targetkey);
        
        }
     };
    const errorkey=(props.useSimpleKey?props.name:"data."+props.name);


    const hasError=(props.errors && props.errors[errorkey]?true:false);


    const onFocus=(e)=>{

        if(props.onFocus){
          let name = e.target.name;
          let value = e.target.value;
          props.onFocus(name,(value),props.index);
        
        }
    };



    //console.log(props.errors,"data."+props.name,hasError);
    const input=<input onFocus={(e) => {
        onFocus(e);
    }} autoComplete="off" name={props.name} value={props.data[props.name]===null?"":props.data[props.name]} 
    onChange={(e) => {
        changeInputValue(e);
    }} 
    onBlur={(e) => {
        onBlur(e);
    }} 
    />;

    const textarea=<textarea onFocus={(e) => {
        onFocus(e);
    }} autoComplete="off" name={props.name}
    onChange={(e) => {
        changeInputValue(e);
    }} 
    onBlur={(e) => {
        onBlur(e);
    }} value={props.data[props.name]===null?"":props.data[props.name]}></textarea>;
    /*const d={props.edit==true?:{props.data[props.name]}};
   
    */

    if(props.useAsTextArea===true){

        const content2=(props.edit===true?textarea:<span>{props.data[props.name]}</span>)
	return (<div className={"textarea"+(hasError?" error":"")}><label>{props.label}</label>{content2}{hasError}
    {(hasError?<p className="error-text">{props.errors[errorkey]}</p>:null)}
    </div>);



    }else{
   
   const content=(props.edit===true?input:<span>{props.data[props.name]}</span>)
	return (<div className={"input"+(hasError?" error":"")}><label>{props.label}</label>{content}{hasError}
    {(hasError?<p className="error-text">{props.errors[errorkey]}</p>:null)}
    </div>);

    }




}
