
import React,{useState,useEffect} from "react";
import { config } from "../../config";

import { useStore } from "../../store/useStore";

import { apiClient } from "../../apiCall";



export const defaultBossData = {
	loading:true,
	data:[]
	
};

  

export default function BossSelector(props) {

	const { state, dispatch } = useStore();
    const [BossData, setBossData] = useState({
		...defaultBossData
		
    });
	const apiCall = apiClient(state, dispatch);		


    useEffect(() => {
		
		let url="department/"+props.department_id+"/bosses/"+props.effective_from;
		///let data={...WageData};
		console.log("EFFECTIVE FROM MUUTTU",url);
		apiCall.GET_RAW(url, function(status,err_msgs=null,response=null){


			if(status==400){
		
				//setFolderData({...FolderData,errors:err_msgs})
			}else if(status==200){
				
				
				setBossData({data:response.data,loading:false})

				//setData({...response.data,loading:false});
			}






		});
		console.log("props.effective_from",props.effective_from);
		///let data={...WageData};
		
		//apiCall.ADD(url, data,function(status,err_msgs=null,response=null){








		/*fetch( config.apiUrl+"/department/"+props.department_id+"/bosses/"+props.effective_from)
		  .then((res) => res.json())
		  .then(data => setBossData({data:data,loading:false}))
*/
	  }, [props.effective_from]);

	 

	  if(BossData.loading){
		  return (<div className="loading">Ladataan</div>);
	  }
	  const changeBoss=(e)=>{

props.changeBoss(e.target.value,props.index);

	  };
	  console.log("SELECCTED",props.selected);

	  const getBossName=()=>{

		for(var i=0;i<BossData.data.length;i++){

			if(BossData.data[i].id==props.selected){

				return "Esimies: "+BossData.data[i].lastname+" "+BossData.data[i].firstname;

			}
		}

		return "Ei esimiestä";

	  };
	  const getSelect=()=>{

		
		const empty=<option key="0" value="0">Ei lähintä esimiestä (bonus)</option>

		const options=BossData.data.map((option) =>
		<option key={option.id} value={option.id}>{option.lastname} {option.firstname}</option>
		);
        return (<div>
          
        <select onChange={(e) => {changeBoss(e)}}  value={(props.selected===null?0:props.selected)}>{empty}{options}</select>
        
			
        </div>);
        
        };

	 



	return (<div>{props.edit?getSelect():getBossName()}</div>);
}
