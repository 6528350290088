import React,{useState,useEffect} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import  {ReactComponent as ICON_LOCK} from '../icons/lock.svg';
export const defaultData={

	

	showforgot:false,
	forgotsent:false
   
  
	
	
};
export default function LoginForm() {
	const { state, dispatch } = useStore();

	const { user } = state;

	const [Data, setData] = useState({
		...defaultData
		
    });

	const changeInputValue = (e) => {
		let name = e.target.name;
		let value = e.target.value;

		dispatch({ type: "LOGINFORM_INPUT", key: name, value: value }, [dispatch]);
	};
	

	const showForgot=(e)=>{

		setData({...Data,showforgot:!Data.showforgot});
	};
	const sendForgot=()=>{

		let bodyFormData = new FormData();

		bodyFormData.set("email", user.email);
		

		
		axios({
			url: config.apiUrl + "/forgot",
			method: "post",
			data: bodyFormData
			
		}).then((response) => {

            console.log(response);
				if (response.status === 200 ) {
					setData({...Data,forgotsent:true});

				} 
			})
			.catch((Error) => {
				
			});
	};



		

	
	const tryLogin = () => {



		if(Data.showforgot){
			sendForgot();
			return;
		}


		let bodyFormData = new FormData();

		bodyFormData.set("email", user.email);
		bodyFormData.set("password", user.password);

		dispatch({ type: "LOGIN" });

		axios({
			url: config.apiUrl + "/auth/login",
			method: "post",
			data: bodyFormData
			
		}).then((response) => {

            console.log(response);
				if (response.status === 200 && response.data.access_token) {
					dispatch({ type: "LOGIN_SUCCESS", payload: response });
				} else {
					dispatch({ type: "LOGIN_FAIL", payload: response });
				}
			})
			.catch((Error) => {
				dispatch({ type: "LOGIN_FAIL" });
			});


		
	};

	const createInput = (label, name) => {
		return (
			<label className="label-input">
				
				<input
					type="text"
					name={name}
					value={user[name]}
					placeholder={label}
					onChange={(e) => {
						changeInputValue(e);
					}}
				/>
			</label>
		);
	};
	const createPwd = (label, name) => {
		return (
			<label className="label-password">
				
				<input
					type="password"
					name={name}
					placeholder={label}
					value={user[name]}
					onChange={(e) => {
						changeInputValue(e);
					}}
				/>
			</label>
		);
	};

	return (
		<main className="green">
			
			<div className="loginContainer">
			<div className="holder">
			<div className="loginForm">
				
				<div className="icon">
					<ICON_LOCK/>
				</div>

				<form
					onSubmit={(e) => {
						e.preventDefault();
						e.stopPropagation();
						tryLogin();
					}}
				>
						
					{!Data.forgotsent?createInput("Sähköposti", "email"):null}
					{Data.showforgot===false?createPwd("Salasana", "password"):null}

					{Data.forgotsent&&<p className="text">Uusi salasana toimitettu sähköpostiisi</p>}

					<div className="submitholder">
						
						
						{(!user.loading && !Data.forgotsent)&& (
						<input
							type="submit"
							className="button primary"
							name="login"
							value={Data.showforgot===false?"Kirjaudu":"Palauta salasana"}
						/>
					)}</div>
					
				</form>
				
			</div>
			{!Data.forgotsent?
			<p className="forgot"><span onClick={(e)=>showForgot(e)}>{Data.showforgot===false?"Unohtuiko salasana?":"Palaa kirjautumiseen"}</span></p>
			:null}
		
			</div></div></main>
	);
}
