import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";

import { config } from "../config";

import InputDouble from "./form/InputDouble.js";

import Loans from "./form/Loans.js";
import LoanLyhennykset from "./form/LoanLyhennykset.js";
import PersonTopNav from "./PersonTopNav.js";
import { apiClient } from "../apiCall";

import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";



  export const defaultUserData={

	loaded:false,
	edit:false,
	data:null,
	orginal:null
	
};


export const DefaultLoanData={
	palkkakausi:"2021-01-01",
	
	laina:0,
	takaisinmaksu:0,
	


};
export default function PersonLoan() {
	const { state, dispatch } = useStore();
    let { id } = useParams();
  
    const [UserData, setUserData] = useState({
		...defaultUserData
		
    });

	const apiCall = apiClient(state, dispatch);

	const sortData=(data)=>{


		
		data.settings.sort((a, b) => Date.parse(a.effective_from) < Date.parse(b.effective_from) ? 1 : -1)


		return data;

	};

    useEffect(() => {
		fetch( config.apiUrl+"/persons/"+id,{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })}).then((res) => res.json())
		  .then(data => setUserData({data:sortData(data),orginal:JSON.parse(JSON.stringify(sortData(data))),loaded:true}))

	  }, []);






	
	if(!state.users.items || !state.common.settings){
		return null;
	}

	

	

	if(!UserData.loaded || !state.companies.companies){
        return (<div>Ladataan</div>);
    }
	const changeInputValue = (name,value) => {
	
       setUserData({...UserData,data:{...UserData.data,[name]:value}})



        //dispatch({ type: "LOGINFORM_INPUT", key: name, value: value }, [dispatch]);
	};
	
	const changeEditMode=(e)=>{
		if(!UserData.edit){
			setUserData({...UserData,"edit":true});
		}else{

			let d=JSON.parse(JSON.stringify(UserData.orginal));
			setUserData({...UserData,"edit":false,"data":{...d}});

		}
	
	};

	
	
	


const tryToSave=()=>{

	let url="persons/"+id+"/loans";
	let data={loans:[...UserData.data.loans],lyhennys:UserData.data.lyhennys};
	
	apiCall.ADD(url, data,function(status,err_msgs=null,response){
	
		if(status==400){

			//setFolderData({...FolderData,errors:err_msgs})
		}else if(status==200){
			
			setUserData({...UserData,"edit":false,data:sortData(response.data),orginal:JSON.parse(JSON.stringify(sortData(response.data)))});
			


			apiCall.GET("users");
		}

	}).then(() => {
		//apiCall.GET('destinations');
		
	});




	
};
const handleLoanAdding=(e)=>{

	var loans = [...UserData.data.loans,{...DefaultLoanData,palkkakausi: state.common.current_period}];

	setUserData({...UserData,data:{...UserData.data,loans:loans}})


};
const editLoan=(name,value,index)=>{

	

		let loans=[...UserData.data.loans];
		loans[index]={...loans[index],[name]:value}
		setUserData({...UserData,data:{
			...UserData.data,
			
			loans:loans}})




	



};

const changeLainanLyhennys = (name,value) => {

	setUserData({...UserData,data:{
		...UserData.data,
		[name]:value
		
	}})
	//props.onChange(name,value);
};

	return (

		<div className={"mycontent person-content"+((UserData.data.department?" "+UserData.data.department.style:null))}>
			
				<div className="inner">
				<div className="box">

				<h2>{UserData.data.firstname} {UserData.data.lastname}</h2>

				<div className={"edit-btn "+(UserData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Muokkaa</span></div>

					{UserData.edit?<div className="save-btn" onClick={tryToSave}><span>Tallenna</span></div>:null}


					<PersonTopNav  id={UserData.data.id}/>
					<div className="form-blocks">
				

					<div className="form-block settings">
						<h3>
						{UserData.edit?
						<div className="edit-btn" onClick={(e) => {
      				  handleLoanAdding(e);
   				 }}><span>Lisää</span></div>:null}</h3>




						<Loans onChange={editLoan} changeLainanLyhennys={changeLainanLyhennys}  loans={UserData.data.loans} data={UserData.data} payments={UserData.data.loan_payments} edit={UserData.edit} />

					</div>


					</div>
					
				</div>
					
			</div>
			
			
		</div>
	);
}
/*<SettingPair name="pohjapalkka" onChange={changeSettingValue} label="Pohjapalkka" defaultSettings={state.common.settings} settings={UserData.data.settings} edit={UserData.edit} />
						<SettingPair name="auto_leasing" onChange={changeSettingValue} label="Autoleasing" defaultSettings={state.common.settings} settings={UserData.data.settings} edit={UserData.edit} />
						<SettingPair name="ulosotto" onChange={changeSettingValue} label="Ulosotto" defaultSettings={state.common.settings} settings={UserData.data.settings} edit={UserData.edit} />
						<SettingPair name="puhelinetu" onChange={changeSettingValue} label="Puhelinetu" defaultSettings={state.common.settings} settings={UserData.data.settings} edit={UserData.edit} />
						<SettingPair name="lounassetelit" onChange={changeSettingValue} label="Lounassetelit" defaultSettings={state.common.settings} settings={UserData.data.settings} edit={UserData.edit} />
						

*/ 