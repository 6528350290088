import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputText from "./form/InputText.js";
import Alaiset from "./Alaiset.js";
import InputSelect from "./form/InputSelect.js";
import Period from "./form/Period.js";
import CustomDate from "./form/CustomDate.js";
import DateSelect from "./form/DateSelect.js";

import SettingPair from "./form/SettingPair.js";
import Settings from "./form/Settings.js";
import EsimiesAndRole from "./form/EsimiesAndRole.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";


import TaxRates from "./form/TaxRates.js";
import Loans from "./form/Loans.js";
import BonusTable from "./BonusTable.js"
import PersonTopNav from "./PersonTopNav.js";
import { apiClient } from "../apiCall";
import TopNav from "./TopNav.js";
import PersonRolesEditor from "./form/PersonRolesEditor.js";
// date-fns
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// or for Day.js

import {
	browserHistory,
    useParams
  } from "react-router-dom";
import {
	
	
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";


  const d = new Date();
  let current_month = d.getFullYear()+"-";
  
  if(d.getMonth()<10){
	  current_month+="0"+d.getMonth();
  }else{

	current_month+=d.getMonth();
  }

  current_month+="-01";


 
  export const defaultUserData={
	
	firstname:null,
	lastname:null,
	streetaddress:null,
	city:null,
	department_id:null,
	email:null,
	myyjatunnus:null,
	bank_name:null,

	postalcode:null,
	bank_bic:null,
	bank_iban:null,
	birth_date:null,
	start:current_month,
	start_date:null,
	end_date:null,
	roles:[{effective_from:current_month,
		roles_id:0,
		boss_id:null,
		bonuses:[]}]
	
};

export const DefaultRequestErrors={

	errors:null
}
export default function AddPerson() {
	const { state, dispatch } = useStore();
    let { id } = useParams();
	


	const [startDate, setStartDate] = useState(new Date());

	const [IsStored, setIsStored] = useState(false);
    const [UserData, setUserData] = useState({
		...defaultUserData
		
    });
	const [RequestErrors,setRequestErrors] = useState({
		...DefaultRequestErrors
			
		});

		const onFocus=(name,value,index)=>{
			let key=name;
		
			let errors={...RequestErrors.errors};
			errors[key]=null;
			setRequestErrors({errors:{...errors}});
		};


	const apiCall = apiClient(state, dispatch);
	const changeBonuses=(index,value)=>{

		index=0;
		let roles=[...UserData.roles];
		value=parseInt(value);
		
		if(roles[index].bonuses.includes(value)){
			

			roles[index].bonuses.splice(roles[index].bonuses.indexOf(value),1);
			
		}else{

			roles[index].bonuses.push(value);
		}


		setUserData({...UserData,roles:[
			...roles]})


	}
const changeRoles=(name,value,index)=>{
		



	let roles=[...UserData.roles];

	if(name==="roles_id" || name==="effective_from"){

		roles[index]={...roles[index],[name]:value,"boss_id":null}
	
	}else{

		roles[index]={...roles[index],[name]:value}
	}

	setUserData({...UserData,roles:[
		...roles]})

		//console.log("ROLES",roles);





};
const changeStart=()=>{


};
	const changeInputValue = (name,value) => {
	

		if(name=="start_date"){
			// changeRoles=(name,value,index)=>{

			var sp=value.split("-");
			if(sp.length==3){
				sp[2]="01";

				//console.log("SP",sp.join("-"));
				//changeRoles("effective_from",sp.join("-"),0);

				
			}
			let roles=[...UserData.roles];
			//VAIHDA effective_from 2021-02-01 0
			roles[0]={...roles[0],"effective_from":sp.join("-"),"boss_id":null}
			setUserData({...UserData,[name]:value,roles:[...roles]});
			console.log("UUDER TOLES",roles);
		}else{
		if(name=="department_id" && value=="0"){
			value=null;
		}
		setUserData({...UserData,[name]:value});
 
 
	}
		 //dispatch({ type: "LOGINFORM_INPUT", key: name, value: value }, [dispatch]);
	 };
	 const changeRole=(value)=>{

		setUserData({...UserData,roles_id:value,boss_id:null});
	};
	const changeEsimies=(value)=>{

		setUserData({...UserData,boss_id:value});

	};

	
	if(!state.companies.companies || !state.common.roles){
		
	 
        return (<div>Ladataan</div>);
    }
	const tryToSave=()=>{

		let url="persons/add";
		let data={...UserData};
		
		apiCall.ADD(url, data,function(status,err_msgs=null){
		
			if(status==400){

				console.log(err_msgs);
				console.log(RequestErrors);
				//setErrors({errors:{...err_msgs}})
				setRequestErrors({errors:{...err_msgs}});
	
				//setFolderData({...FolderData,errors:err_msgs})
			}else if(status==200){

				
				//setUserData({...UserData,"edit":false});
				apiCall.GET("users");
				setIsStored(true);
			}
	
		}).then(() => {
			//apiCall.GET('destinations');
			
		});
	
	
	
	
		
	};
	if(state.companies.loading){
return null;
	}
	const addAnother=()=>{


		setUserData({...defaultUserData});
		setIsStored(false);
	};
	if(IsStored){

		return (<div><div className="thanks"><h1>Henkilö lisätty</h1><p><a onClick={addAnother}>Lisää uusi</a></p></div></div>);
	}
	const mycompany=state.companies.companies.find(company => company.id == UserData.department_id);


	//const roles=(mycompany?mycompany.roles:[]);

	
//const possible_subs=state.users.items.filter(person=>person.roles_id<UserData.roles_id && UserData.department_id===person.department_id);
//console.log("all",all);

console.log("RequestErrors",RequestErrors);
	return (
		<div className="persons mycontent">
			<div className="inner">
		
			<div className='tyontekijat-topic topic-box'>
					<div className="inner"><h2>Työntekijät <span className="tri">►</span> Lisää uusi <span className="tri">►</span>  {UserData.firstname} {UserData.lastname}</h2>	</div>
				</div>
				
		<TopNav />
		
			
		<div className="person content">
		
				<div className="inner">
				<div className="box">

				<h2></h2>

				

			



					<div className="form-block">

						<h3>Perustiedot</h3>
						
						<div className="left">
							<InputText label="Etunimi" onChange={changeInputValue} data={UserData} onFocus={onFocus} errors={RequestErrors.errors} useSimpleKey={true} name="firstname" edit={true}/>
							<InputText label="Sukunimi" onChange={changeInputValue} data={UserData} onFocus={onFocus} errors={RequestErrors.errors} useSimpleKey={true}  name="lastname" edit={true}/>
							
							{/*<CustomDate useSimpleKey={true}  label="Syntymäaika" name="birth_date" onChange={changeInputValue} data={UserData} onFocus={onFocus} errors={RequestErrors.errors} edit={true}/>*/}
							
							<DateSelect edit={true} errors={RequestErrors.errors}  name="birth_date" label="Syntymäaika" onChange={changeInputValue} data={UserData} />
							
							<InputText label="Email" onChange={changeInputValue} data={UserData} onFocus={onFocus} errors={RequestErrors.errors} useSimpleKey={true}  name="email" edit={true}/>

							
							<InputSelect label="Yritys" zero="Valitse yritys" onChange={changeInputValue} onFocus={onFocus} errors={RequestErrors.errors} options={state.companies.companies} data={UserData} name="department_id" edit={true}/>

							<CustomDate useSimpleKey={true}  label="Työsuhde alkaa" name="start_date" onChange={changeInputValue} data={UserData} onFocus={onFocus} errors={RequestErrors.errors} edit={true}/>
						








							{UserData.department_id && UserData.start_date?
							<PersonRolesEditor hideperiod={true} index={0} onChangeBonuses={changeBonuses} department_id={UserData.department_id}  onChange={changeRoles} roles={UserData.roles}  edit={true}/>
							:null}
					
						</div>
						



						<div className="right">

						
						<InputText label="Katuosoite" onChange={changeInputValue} data={UserData} onFocus={onFocus} useSimpleKey={true}  errors={RequestErrors.errors} name="streetaddress" edit={true}/>
						<InputText label="Postinumero" onChange={changeInputValue} data={UserData} onFocus={onFocus} useSimpleKey={true}  errors={RequestErrors.errors} name="postalcode" edit={true}/>
						<InputText label="Kaupunki" onChange={changeInputValue} data={UserData} onFocus={onFocus} useSimpleKey={true}  errors={RequestErrors.errors} name="city" edit={true}/>
						
						<InputText label="Myyjätunnus" onChange={changeInputValue} data={UserData} onFocus={onFocus} errors={RequestErrors.errors} useSimpleKey={true}  name="myyjatunnus" edit={true}/>
						</div>

					</div>
					<div className="form-block">

						<h3>Pankkitiedot</h3>
						
						<InputText label="Pankki" onChange={changeInputValue} data={UserData} onFocus={onFocus} useSimpleKey={true}  errors={RequestErrors.errors} name="bank_name" edit={true}/>
						<InputText label="BIC" onChange={changeInputValue} data={UserData} onFocus={onFocus} useSimpleKey={true} errors={RequestErrors.errors} name="bank_bic" edit={true}/>
						<InputText label="IBAN" onChange={changeInputValue} data={UserData} onFocus={onFocus} useSimpleKey={true}  errors={RequestErrors.errors} name="bank_iban" edit={true}/>

					</div>
					<div className="btn-block">
					<div className="save-btn" onClick={tryToSave}><span>Tallenna</span></div>

					</div>
					

					
				</div>
					
			</div>
			
			
		</div>
		</div>
		</div>
	);
}
/*<SettingPair name="pohjapalkka" onChange={changeSettingValue} label="Pohjapalkka" defaultSettings={state.common.settings} settings={UserData.data.settings} edit={UserData.edit} />
						<SettingPair name="auto_leasing" onChange={changeSettingValue} label="Autoleasing" defaultSettings={state.common.settings} settings={UserData.data.settings} edit={UserData.edit} />
						<SettingPair name="ulosotto" onChange={changeSettingValue} label="Ulosotto" defaultSettings={state.common.settings} settings={UserData.data.settings} edit={UserData.edit} />
						<SettingPair name="puhelinetu" onChange={changeSettingValue} label="Puhelinetu" defaultSettings={state.common.settings} settings={UserData.data.settings} edit={UserData.edit} />
						<SettingPair name="lounassetelit" onChange={changeSettingValue} label="Lounassetelit" defaultSettings={state.common.settings} settings={UserData.data.settings} edit={UserData.edit} />
						

*/ 