const defaultState = {
	
	loading:true,
	settings: null,
	firstLoad:false,
	mainLoader:true,
	globalsettings:null,
	globalsettingsorginal:null,
	bonuschart:null,
	sortIndexPersons:"lastname",
	sortIndexMode:"asc",
	sortIndexPalkanlaskenta:"role",
	sortIndexYritysLainat:"lastname",
	sortIndexModePalkanlaskenta:"desc",
	min_wage_period:null,
	max_wage_period:null,
	bonus_styles:null,
	show_quitted:false,
	notes:null,
	adminroles:null,
	me:null

};

export const commonInitialState = (() => {
	
	return defaultState;
})();

export const commonReducer = (state = commonInitialState, action) => {
	let companies = state;
	switch (action.type) {
		case "COMMON_LOGOUT":
			companies = {
				...defaultState
			}
		break;
		case "COMMON_SET_ORDER_YRITYSLAINAT":

			companies = {
				...state,
				sortIndexYritysLainat:action.sortIndexYritysLainat,
				sortIndexMode:(action.sortIndexMode?action.sortIndexMode:"asc")
			}
		break;
		case "SORT_PALKANLASKENTA":
			
			
			companies = {
				...state,
				sortIndexPalkanlaskenta:action.sortIndexPalkanlaskenta,
				sortIndexModePalkanlaskenta:(action.sortIndexModePalkanlaskenta?action.sortIndexModePalkanlaskenta:"asc")
			}
		break;
		case "COMMON_SET_SHOW_QUITTED":
			companies = {
				...state,
				show_quitted:action.show_quitted
				
			}

		break;
		case "COMMON_SET_ORDER":

		
		companies = {
			...state,
			sortIndexPersons:action.sortIndexPersons,
			sortIndexMode:(action.sortIndexMode?action.sortIndexMode:"asc")
		}
		break;
		case "COMMON_LOADED":

			
			companies = {
				...state,
				mainLoader:false,
				loading: false,
				settings: action.payload.data.settings,
				globalsettings: action.payload.data.globalsettings,
				globalsettingsorginal:JSON.parse(JSON.stringify((action.payload.data.globalsettings))),
				bonuschart:action.payload.data.bonuschart,
				bonuschartorginal:JSON.parse(JSON.stringify((action.payload.data.bonuschart))),

				bonuschart_osasto:action.payload.data.bonuschart_osasto,
				bonuschart_osastoorginal:JSON.parse(JSON.stringify((action.payload.data.bonuschart_osasto))),

				bonuschart_alaiset:action.payload.data.bonuschart_alaiset,
				bonuschart_alaisetorginal:JSON.parse(JSON.stringify((action.payload.data.bonuschart_alaiset))),

				current_period:action.payload.data.current_period,
				ownbuys:action.payload.data.ownbuys,
				roles:action.payload.data.roles,
				bonus_styles:action.payload.data.bonus_styles,
				min_wage_period:action.payload.data.min_wage_period,
			max_wage_period:action.payload.data.max_wage_period,
				jatkuvat:action.payload.data.jatkuvat,
				notes:action.payload.data.notes,
				adminroles:action.payload.data.adminroles,
				me:action.payload.data.me


			};
			break;

		case "COMMON_LOADING":
			companies = {
				...state,
				
				loading: true,
				firstLoad:true
				
			};
			break;

		case "COMMON_FAIL":
			
			companies = {
				...state,
				
				loading: true,
				settings: null
			};
			break;
		

		default:
			companies = state;
		break;
    }
    return companies;
};