import React,{useState,useEffect} from "react";
import MedicLogo from '../icons/medic.svg';

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import InputDouble from "./form/InputDouble.js";
import TopNav from "./TopNav.js";
import AddPerson from "./AddPerson.js";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";

export default function Persons() {
	const { state, dispatch } = useStore();

  
	const sortIndex="lastname";

	
	if(!state.users.items){
		return null;
	}
	
	if(!state.companies.companies){

		return null;
	}
	if(!state.common){

		return null;
	}

	console.log("SHOW",state.common.show_quitted);
	/*const listItems = state.users.items.map((item) =>
  <tr key={item.id}><td><Link to={"/persons/"+item.id}>{item.lastname} {item.firstname}</Link></td>
  <td>{item.department.name}</td>
  <td>{((item.current_role && item.current_role.role)?item.current_role.role.name:"-")}</td>
  </tr>
	);*/
	const keyCount=3;


	
    const getSorting=(a,b)=>{

		let av=0;
		let bv=0;
		if(state.common.sortIndexPersons=="role"){


if(a.current_role && a.current_role.role){

	av= a.current_role.role.name;
}else{

	av="-";
}
if(b.current_role && b.current_role.role){

	bv= b.current_role.role.name;
}else{

	bv="-";
}


		}else{
		 av= a[state.common.sortIndexPersons].toUpperCase();
		 bv=b[state.common.sortIndexPersons].toUpperCase();
		}
		
		
		console.log(state.common.sortIndexMode);
			let v= av> bv ? 1 : -1;
			return v*(state.common.sortIndexMode==="asc"?1:-1);
	

		
		//return a[state.common.sortIndexPersons].toUpperCase() > b[state.common.sortIndexPersons].toUpperCase() ? -1 : 1;
		

	};

	const headerKeys=[
		["lastname","Nimi"],
		["role","Rooli"],
		[null,"Info"]
	];
	const setOrdering=(order)=>{

		if(order){

			//alert(order);
			let mode="asc";
			if(state.common.sortIndexPersons===order){

				mode=(state.common.sortIndexMode==="asc"?"desc":"asc");
			}

			dispatch({type:"COMMON_SET_ORDER",sortIndexPersons:order,sortIndexMode:mode});
		}

	};

	const getHeader=()=>{

		return headerKeys.map((item,index) =>

		<th onClick={(e) => {
			setOrdering(item[0]);
		}} className={item[0]?"orderable "+(item[0]===state.common.sortIndexPersons?"order-active "+state.common.sortIndexMode:null):null} key={index}>{item[1]}</th>
		
		
		  );


	};
const isQuitted=(user)=>{
	
	if(user.end_date){
	
		var current=new Date(state.common.current_period);
		var end_date_month=new Date(user.end_date);
		

		/*end_date_month.setMonth(end_date_month.getMonth()+1);

		end_date_month.setDate(1);*/
		
		if(end_date_month<current){

			return true;
		}


		//console.log(state.common);
	}
	return false;
	
};
const filterPeoples=(items)=>{

	let peoples=[];
	if(state.common.show_quitted){

		return items;

	}else{

		let peoples=[];
		for(var i=0;i<items.length;i++){

			if(!isQuitted(items[i])){
				peoples.push(items[i]);
			}
		}

		return peoples;

	}


};
	const userHeader=<tr className="text-header">{getHeader()}</tr>;

	const getTyonTekijat=(company_id)=>{

		let peoples=filterPeoples(state.users.items);
		return peoples.sort((a, b) => getSorting(a,b)).filter(person => person.department_id===company_id).map((item) =>
		
		<tr className="data-row" key={item.id}><td><Link to={"/persons/"+item.id}>{item.lastname} {item.firstname}</Link></td>
		
		<td><Link to={"/persons/"+item.id}>{((item.current_role && item.current_role)?item.current_role.name:"-")}</Link></td>
		<td>{isQuitted(item)?"Lopettanut "+item.end_date:"Aktiivinen"+(item.end_date?", päättyy "+item.end_date:"")}</td>
		
		</tr>

		  );




	};


	const departments=state.companies.companies.map((item) =>
		

		<tbody key={item.id} className={item.style}>
			<tr className="header"><th colSpan={keyCount}>
				
				<h2><span className="small-info">Yritys</span>{item.name}</h2>
				
				
				</th></tr>
			{userHeader}
			{getTyonTekijat(item.id)}
		</tbody>


	  );

	const setShowQuitted=()=>{

		dispatch({type:"COMMON_SET_SHOW_QUITTED",show_quitted:!state.common.show_quitted});

	};

	return (
		<div className="mycontent">
			
			<div className="inner">


			<div className='tyontekijat-topic topic-box'>
					<div className="inner"><h2>Työntekijät</h2>	<div className="add-new-person-link-holder"><Link to="/persons/add">Lisää henkilö</Link></div><div onClick={setShowQuitted} class={"show-quitted"+(state.common.show_quitted?" active":"")}><span>Näytä lopettaneet</span></div>
				</div>
			</div>

				
				<div className="box">
		
				
				
				<table className="table-new">
				
				
					
				{departments}
						
				
				
				
			</table></div>
		</div></div>		
	);
}
