import InputProcent from "./InputProcent.js";
import InputDouble from "./InputDouble.js";
import Period from "./Period.js";
import React,{useState,useEffect} from "react";
import { useStore } from "../../store/useStore";
import BossSelector from "./BossSelector.js"


export default function PersonRolesEditor(props) {


    const { state, dispatch } = useStore();



   
    const  changePeriod=(name,value,index)=>{

        props.onChange(name,value,index);
      //  props.onChange("boss_id","0",index);

       
    };
    const changeRole = (e,index) => {
		
		let value = parseInt(e.target.value);
       
        props.onChange("role_id",value,index);
     
     
	};
 
  const removePersonRole=(index)=>{

    props.removePersonRole(index);

  };

  
    const header=<tr><th>{props.hideperiod?null:"Voimaantulo"}</th><th>Rooli</th><th>Esimies</th><th>Bonukset</th><th></th></tr>;


    const empty=<option key="0" value="0">--valitse rooli--</option>




    
    const options=state.common.roles.map((option) =>
    <option key={option.id} value={option.id}>{option.name}</option>
    );
    
  const changeBoss=(boss_id,index)=>{



  props.onChange("boss_id",(boss_id=="0"?null:parseInt(boss_id)),index);



  };

 const changeBonus=(e,index)=>{
 

 props.onChangeBonuses(index,e.target.value);


 };
  const checkbbox=(name,value,index,checked=false)=>{



   return (<label>{name}<input type="checkbox"  onChange={(e) => {changeBonus(e,index)}}  value={value} checked={checked}/></label>);
  
  
  
    };
  const getBonusSelectors=(item,index_role)=>{

    
 
    const options=state.common.bonuschart_alaiset.filter(el=>(el.style.limit_to_role==item.role_id || el.style.limit_to_role===null) ).map((option,index) =>{
     

      if(!props.edit){

        if(item.bonuses.includes(option.id)){

return (<p>{option.name}</p>);
        }else{

          return null;
        }
        return <p key={index} className="bonus-line">{checkbbox(option.name,option.id,index_role,item.bonuses.includes(option.id))}{option.id}</p>



      }

    return <p key={index} className="bonus-line">{checkbbox(option.name,option.id,index_role,item.bonuses.includes(option.id))}</p>
   
  
  
  });


return <div className="bonus-select">{options}</div>;

  };
    const getSelect=(item,index)=>{
     

console.log("SELECTE",props.edit);
   
      if(!props.edit){

        return (<tr key={index} className="role-inner-table">
        
    <td>{!props.hideperiod?<Period onChange={changePeriod} name="effective_from" index={index}  value={item.effective_from} edit={props.edit}/>:null}</td>

          <td>{item.name}</td>
          <td>{(item.role_id==3?getBossSelector(item,index):"-")}</td>
          <td>{getBonusSelectors(item,index)}</td>
          
          <td>

  {!props.hideperiod && props.edit?<span className='remove' onClick={(e) => {
        removePersonRole(index);
    }} >poista</span>:null}
  </td>
  
          </tr>)
        
     
      }


      let errorkey="roles."+index+"."+"role_id";
      let hasError=(props.errors && props.errors[errorkey]?true:false);
        return (<tr className="role-select" key={index}>
       <td>{!props.hideperiod?<Period onChange={changePeriod} name="effective_from" index={index}  value={item.effective_from} edit={props.edit}/>:null}</td>

       <td><select onChange={(e) => {changeRole(e,index)}}  value={item.role_id}>{empty}{options}</select>
        {hasError?<p className="error">{props.errors[errorkey]}</p>:null}
        </td>
        
        {(item.role_id==3?<td>{getBossSelector(item,index)}</td>:<td>-</td>)}
        <td>{getBonusSelectors(item,index)}</td>
        <td>

{!props.hideperiod && props.edit?<span className='remove' onClick={(e) => {
      removePersonRole(index);
  }} >poista</span>:null}
</td>
        </tr>);
        
        };



        
        
   
  const getBossSelector=(item,index)=>{

    return <div><BossSelector edit={props.edit} changeBoss={changeBoss} index={index} department_id={props.department_id} selected={item.boss_id} effective_from={item.effective_from}/></div>

  };
   


console.log("roolist",props.roles);
    const items = props.roles.map((item,index) =>




//{empty}{options}

 
  
  getSelect(item,index )

     


    );


	return (<table className="role-editor"><thead>{header}</thead><tbody>{items}</tbody></table>);
}
