

import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import TopNav from "./TopNav.js";
import Period from "./form/Period.js";
import InputText from "./form/InputText.js";
import InputDouble from "./form/InputDouble.js";
import InputSelect from "./form/InputSelect.js";
import { apiClient } from "../apiCall";
export const defaultNew={

	value:null,
	effective_from:0,
	description:"",
	persons_id:0,
	inited:false,

};
export const defaultJatkuvat={

	items:null

};

export default function CreditCard() {



	
	const { state, dispatch } = useStore();
	const {common,ui}=state;
	console.log("CCIMMIN",state);
	const apiCall = apiClient(state, dispatch);
	const [NewData, setNewData] = useState({
		...defaultNew,
		effective_from:null
		
    });
	const [Jatkuvat, setJatkuvat] = useState({
		...defaultJatkuvat
		
		
    });


	useEffect(() => {
		
		
	  if(NewData.effective_from){

		let url="jatkuvat/"+NewData.effective_from;
		apiCall.GET_RAW(url, function(status,err_msgs=null,response=null){
	  
		  
		  if(status==400){
		
			//setFolderData({...FolderData,errors:err_msgs})
		  }else if(status==200){

			console.log(response.data);
			setJatkuvat({...Jatkuvat,items:[...response.data]});
		 //  setMemoData({...MemoData,loading:false,data:response.data,orginal:[...JSON.parse(JSON.stringify(response.data))]})
	  
		  }
	  
	  
	  
	  
		});
	  
	  
	}
	  
		}, [NewData.effective_from]);


	useEffect(() => {


			//if(ui){
//alert("SAIN"+state.ui.period);
				//if(!NewData.effective_from || NewData.effective_from==0){
					setNewData({...NewData,effective_from:state.ui.period,inited:true});
				//}
			//}
	  }, [ui]);

	  const changePeriod=(d,v)=>{
		//alert("CHANGE"+v);
		dispatch({ type: "CHANGE_PERIOD", period: v });
		//setLaskentaData({...LaskentaData,period:v});
		
		
	};	

	console.log("COMMON OWN",common.ownbuys);
	if(common.loading || state.users.loading || !NewData.effective_from || Jatkuvat.items==null){
		//console.log("NULLL");
		return null;
	}
 /*  const listItems=common.ownbuys.map(buy=>(

	return   <tr key={buy.id}></tr>

   ));
*/

const tryToSave=(e)=>{

	e.preventDefault();

	let url="creditcard";
	let data={...NewData};
	data.value=parseFloat(data.value);
	apiCall.ADD(url, data,function(status,err_msgs=null){

		if(status==400){

			//setFolderData({...FolderData,errors:err_msgs})
		}else if(status==200){
		//	setUserData({...UserData,"edit":false});

			setNewData({...NewData,value:null,description:"",
			persons_id:0});


			apiCall.GET("common");
		}

	}).then(() => {
		//apiCall.GET('destinations');
		
	});





}
const formatDate=(date)=>{

	if(date==null){
		return "-";
	}
	let dates=date.split("-");
  
    if(dates.length===3){
        return dates[1]+"/"+dates[0];
    }else{

		
	}
	return null;

}
const getPersonName=(id)=>{

	let user=(state.users.items.find(user => user.id ===id));

	if(!user){
		return null;	
	}
	return user.lastname+" "+user.firstname;
}



const selected=common.ownbuys.filter(function(buy){

	return buy.palkkakausi===NewData.effective_from && buy.is_positive===0;

});

const removeMe=(id)=>{


	apiCall.GET("common");

	let url="creditcard/remove/"+id;
	let data={};
	
	apiCall.ADD(url, data,function(status,err_msgs=null,response){
			console.log("REMOVE");
			
			if(status==400){
	
				//setFolderData({...FolderData,errors:err_msgs})
			}else if(status==200){

				apiCall.GET("common");
		
			}
	
		}).then(() => {
			//apiCall.GET('destinations');
			
		});



		

}
function isDateBetween(target, start, end) {
	const targetDate = new Date(target);
	const startDate = new Date(start);

  
	if (targetDate.toString() === 'Invalid Date' || startDate.toString() === 'Invalid Date' ) {
	  throw new Error('One of the dates is not in the valid "YYYY-MM-DD" format.');
	}
	if(end==null){
		return targetDate >= startDate;

	}else{
		const endDate = new Date(end);
		return targetDate >= startDate && targetDate <= endDate;
	}

  }
/*
const selectedJatkuvat=common.jatkuvat.filter(function(jatkuva){

	return isDateBetween(NewData.effective_from,jatkuva.alkaa,jatkuva.paattyy);

});*/


const selectedJatkuvat=Jatkuvat.items;
console.log("selectedJatkuvat",selectedJatkuvat);


const changeJatkuva=(name,value,index)=>{

	let items=[...Jatkuvat.items];
	items[index][name]=value;
	setJatkuvat({...Jatkuvat,items:[...items]});
};
const hyvaksyJatkuva=(index)=>{


	let url="hyvaksyjatkuva";
	if(parseFloat(Jatkuvat.items[index].summa)==0 || Jatkuvat.items[index].selite.trim()==""){
		return false;
	}

	let data={
		...Jatkuvat.items[index],
		targetperiod:NewData.effective_from

	};
	apiCall.ADD(url, {...data},function(status,err_msgs,response){

		if(status==400){
  
			//setFolderData({...FolderData,errors:err_msgs})
		  }else if(status==200){
			setJatkuvat({...Jatkuvat,items:[...response.data]});

			apiCall.GET("common");
		  }
		
		});


};

const listItemsJatkuvat=selectedJatkuvat.map((buy,index)=>(


	<tr key={buy.id}><td>{formatDate(buy.alkaa)}</td><td>{formatDate(buy.paattyy)}</td><td>{getPersonName(buy.persons_id)}</td><td><InputDouble edit={true} onChange={changeJatkuva} data={buy} index={index} name="summa" /></td><td><InputText onChange={changeJatkuva} edit={true} data={buy} name="selite" index={index} /></td><td><span onClick={(e)=>hyvaksyJatkuva(index)} className="hyvaksy">Hyväksy</span></td></tr>
	
	));




   const listItems=selected.map(buy=>(


<tr key={buy.id}><td>{formatDate(buy.palkkakausi)}</td><td>{getPersonName(buy.persons_id)}</td><td>{buy.value}</td><td>{buy.description}</td><td><span className="remove-me"  onClick={(e) => {
        removeMe(buy.id);
    	}}>Poista</span></td></tr>

));


   const changeValue=(n,v,index=null)=>{
	
	setNewData({...NewData,[n]:v});

   };

   console.log("LÄPI RENDER PERSON ID",NewData.persons_id);
//state.ccommon
/*const persons=[
	
	
	{id:1,name:"juk"}



];*/
const persons=state.users.items.map(person=>(


	{id:person.id,name:person.lastname+" "+person.firstname}
	
	));


	console.log("COMMON NETTO",common);


    return (<div className="creditcard content">
    <TopNav  />
    
    <div className="inner">
				<div className="box">
				<h2>Netto vähennykset {NewData.effective_from}</h2>
<div className="nettoform">
	<div className="col"><label>Kausi</label><Period min={state.common.min_wage_period} max={state.common.max_wage_period} onChange={changePeriod} name="effective_from" value={(NewData.effective_from)} edit={true}/></div>

	<div className="col"><InputSelect label="Henkilö" onChange={changeValue} zero="--Valitse henkilö--" options={persons} data={NewData} name="persons_id" edit={true}/></div>
	<div className="col"><InputDouble label="Määrä" onChange={changeValue} data={NewData} name="value" edit={true}/></div>
	<div className="col"><InputText label="Kuvaus" onChange={changeValue} data={NewData} name="description" edit={true}/></div>
	<div className="col store"><button onClick={tryToSave}>Tallenna</button></div>
</div>
			

		<table className="table">
			<thead>
				<tr><th>Kohdekausi</th><th>Henkilö</th><th>Summa</th><th>Kuvaus</th></tr>
			
			</thead>
			<tbody>
				
				{listItems}
					
			</tbody>		
			
			
		</table>
		
		
		<h4>Jatkuvat maksut kuukaudelle</h4>
		<table className="table">
			<thead>
				<tr><th>Alkaa</th><th>Päättyy</th><th>Henkilö</th><th>Summa</th><th>Kuvaus</th></tr>
			
			</thead>
			<tbody>
				
				{listItemsJatkuvat}
					
			</tbody>		
			
			
		</table>
		</div>
		</div></div>


   )





};
