import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputText from "./form/InputText.js";
import Alaiset from "./Alaiset.js";
import InputSelect from "./form/InputSelect.js";

import SettingPair from "./form/SettingPair.js";
import Settings from "./form/Settings.js";
import EsimiesAndRole from "./form/EsimiesAndRole.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";
import Loans from "./form/Loans.js";
import BonusTable from "./BonusTable.js"
import PersonTopNav from "./PersonTopNav.js";
import { apiClient } from "../apiCall";

import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";



  export const defaultUserData={

	loaded:false,
	edit:false,
	data:null,
	orginal:null
	
};

export const DefaultTaxData={
	effective_from:"2021-01-01",
	tax_rate:0,
	addional_tax_rate:0,
	addional_tax_rate_limit:0

};
export const DefaultLoanData={
	palkkakausi:"2021-01-01",
	
	laina:0,
	takaisinmaksu:0,
	lyhennys:0


};
export default function PersonSettings() {
	const { state, dispatch } = useStore();
    let { id } = useParams();
  
    const [UserData, setUserData] = useState({
		...defaultUserData
		
    });

	const apiCall = apiClient(state, dispatch);

	const sortData=(data)=>{


		
		data.settings.sort((a, b) => Date.parse(a.effective_from) < Date.parse(b.effective_from) ? 1 : -1)


		return data;

	};

    useEffect(() => {
		fetch( config.apiUrl+"/persons/"+id,{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })}).then((res) => res.json())
		  .then(data => setUserData({data:sortData(data),orginal:JSON.parse(JSON.stringify(sortData(data))),loaded:true}))

	  }, []);






	
	if(!state.users.items || !state.common.settings){
		return null;
	}

	

	

	if(!UserData.loaded || !state.companies.companies){
        return (<div>Ladataan</div>);
    }
	
	const changeSettingValue=(name,value)=>{

			console.log(UserData.data.settings);
			const index=(getSettingIndex(UserData.data.settings,name));

			if(index!==false){

				
				let type=getSettingType(UserData.data.allsettings,name);
			
				let value_key=type+"_value";
				let setting={...UserData.data.settings[index]};	

					setting.value={...setting.value,[value_key]:value}

				let settings=[...UserData.data.settings];
				settings[index]=setting;
			
				
				setUserData({...UserData,data:{...UserData.data,settings:[...settings]}})
				
				
			}else{

				let obj=getCommonSetting(UserData.data.allsettings,name);
				
				let commonsetting={...obj};

				
				let type=getSettingType(UserData.data.allsettings,name);
				let value_key=type+"_value";
				commonsetting={...commonsetting,value:{[value_key]:value}};
				
				let settings=[...UserData.data.settings,commonsetting];

				setUserData({...UserData,data:{...UserData.data,settings:[...settings]}})



			}



			//UserData.data.settings


	};

	

	const changeEditMode=(e)=>{
		if(!UserData.edit){
			setUserData({...UserData,"edit":true});
		}else{

			let d=JSON.parse(JSON.stringify(UserData.orginal));
			setUserData({...UserData,"edit":false,"data":{...d}});

		}
	
	};

	
	const addNewSettings=(settings_id)=>{

		let settings=[...UserData.data.settings];
			
		settings.push(

			{id:null,rajalla:0,description:null,usersettings_names_id:settings_id,value:0,active:0,effective_from: state.common.current_period}

		);
		
		settings.sort((a, b) => Date.parse(a.effective_from) < Date.parse(b.effective_from) ? 1 : -1)	
		
		setUserData({...UserData,data:{...UserData.data,settings:settings}})




	};
	  const getAsetusIndex=(settings_id,index)=>{



		let c=0;
		for(var i=0;i<UserData.data.settings.length;i++){
			if(settings_id===UserData.data.settings[i].usersettings_names_id){
				
					if(c===index){

						return i;
						
					}

					c++;
			}

		}


		return false;


	  };
	  const remove=(settings_id,index)=>{

		
		//alert(settings_id+":"+index);

		let real=getAsetusIndex(settings_id,index);
			
		if(real!==false){
			let settings=[...UserData.data.settings];

			settings.splice(real, 1);
			setUserData({...UserData,data:{...UserData.data,settings:settings}})
			//settings[real][name]=value;
			/*if(name==="effective_from"){
				settings.sort((a, b) => Date.parse(a.effective_from) < Date.parse(b.effective_from) ? 1 : -1);
			}
			setUserData({...UserData,data:{...UserData.data,settings:settings}})
			*/
		}




	  }
	const paivitaAsetus=(settings_id,name,value,index)=>{

		
		
		let real=getAsetusIndex(settings_id,index);
		
		if(real!==false){
			let settings=[...UserData.data.settings];
			
			settings[real][name]=value;
			if(name==="effective_from"){
				settings.sort((a, b) => Date.parse(a.effective_from) < Date.parse(b.effective_from) ? 1 : -1);
			}
			setUserData({...UserData,data:{...UserData.data,settings:settings}})

		}






	};
	
	


const tryToSave=()=>{

	let url="persons/"+id+"/settings";
	let data={settings:[...UserData.data.settings]};
	
	apiCall.ADD(url, data,function(status,err_msgs=null){
	
		if(status==400){

			//setFolderData({...FolderData,errors:err_msgs})
		}else if(status==200){
			setUserData({...UserData,"edit":false});
			apiCall.GET("users");
		}

	}).then(() => {
		//apiCall.GET('destinations');
		
	});




	
};



	return (

		<div className={"mycontent person-content"+((UserData.data.department?" "+UserData.data.department.style:null))}>
			
				<div className="inner">
				<div className="box">

				<h2>{UserData.data.firstname} {UserData.data.lastname}</h2>

				<div className={"edit-btn "+(UserData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Muokkaa</span></div>

					{UserData.edit?<div className="save-btn" onClick={tryToSave}><span>Tallenna</span></div>:null}


					<PersonTopNav  id={UserData.data.id}/>
				
					<div className="form-blocks">
					
					<div className="form-block settings">
					
						<Settings remove={remove} addNewSettings={addNewSettings} onChange={paivitaAsetus} allSettings={state.common.settings} settings={UserData.data.settings} edit={UserData.edit} />
						
						
					</div>
					
					</div>


				</div>
					
			</div>
			
			
		</div>
	);
}
