import InputDouble from "./InputDouble.js";
import InputBoolean from "./InputBoolean.js";
import InputText from "./InputText.js";
import Period from "./Period.js";

export default function SettingTAble(props) {

   
    

    const changeValue=(name,value,index)=>{
       
        props.onChange(name,value,index);
    };
    const remove=(index)=>{


        props.remove(index);
    }
   
    const items = props.values.map((item,index) =>


 
       <tr key={index}>
          
          <td className="active"><InputBoolean onChange={changeValue} data={item} index={index} name="active" edit={props.edit}/></td>
          {props.userajalla===1?
             <td className="rajalla"><InputBoolean onChange={changeValue} data={item} index={index} name="rajalla" edit={props.edit}/></td>

          
          :null} 
          
          
          <td className="expires"><Period onChange={changeValue} name="effective_from" index={index}  value={item.effective_from} edit={props.edit}/></td>

          
          {item.active==1?
          <td className="double"><InputDouble onChange={changeValue} data={item} index={index} name="value" edit={props.edit}/></td>
          :<td>-</td>}
          {props.usedescription===1?
          <td className="double"><InputText onChange={changeValue} data={item} index={index} name="description" edit={props.edit}/></td>

          :null}
          <td>{props.edit?<span className="btn btn-remove" onClick={(e)=>{
              
              remove(index);

          }}>Poista</span>:null}</td>
        </tr>
      


    );
const getArvoheader=(key)=>{

    const arvot={
        "auto_leasing":"€",
        "ulosotto":"Suojaosuus (€)",
        "pohjapalkka":"Pohjapalkka (€)",
        "puhelinetu":"Puhelinetu (€)",
        "lounassetelit":"Lounassetelit (kpl)",
        "duell_laskup":"Määrä (€)",
        "koululutus_korvaus":"€ / päivä",
        "koulutuspaiva_km_korvaus":"€ / km"

    };
    if(arvot[key]){
        return arvot[key];
    }
    return key;



}
console.log("ediititit",props);
    const head=(props.values.length?<thead><tr><th>Käytössä</th>{props.userajalla===1?<th>Käytä rajalla <i className="raja-info">(mikäli ylittää pohjapalkan rajan)</i></th>:null}<th>Alkaen</th><th>{getArvoheader(props.key_name)}</th>{props.usedescription===1?<th>Selite</th>:null}<th></th></tr></thead>:null);


   
	return (<div className="settings-all"><table>{head}<tbody>{items}</tbody></table></div>);
}
