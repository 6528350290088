import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputText from "./form/InputText.js";
import InputSelect from "./form/InputSelect.js";
import { apiClient } from "../apiCall";

import SettingPair from "./form/SettingPair.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";


import TopNav from "./TopNav.js";
import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";



  export const defaultMemoData={
    editindexes:[],
	  text:"",
    data:null,
    loading:true,
    orginal:null
};


export default function Memo(props) {


  const { state, dispatch } = useStore();

  const apiCall = apiClient(state, dispatch);	
 






const [MemoData, setMemoData] = useState({
    ...defaultMemoData
    
});

useEffect(() => {
  let url="notes";
  

  apiCall.GET_RAW(url, function(status,err_msgs=null,response=null){

	
    if(status==400){
  
      //setFolderData({...FolderData,errors:err_msgs})
    }else if(status==200){
      
     setMemoData({...MemoData,loading:false,data:response.data,orginal:[...JSON.parse(JSON.stringify(response.data))]})

    }




  });




  }, []);


const changeText=(name,value) => {
    
    setMemoData({...MemoData,[name]:value});



  
 };
const changeNoteText=(name,value,index)=>{

    let data=[...MemoData.data];
    data[index]={...data[index],[name]:value};
    setMemoData({...MemoData,data:data});



};
console.log("state.common.notes",state.common.notes);
 if(MemoData.loading){
  return null;
}

const addNew=()=>{


  let url="notesadd";


  apiCall.ADD(url, {note:MemoData.text},function(status,err_msgs,response){
    if(status==400){
  
      //setFolderData({...FolderData,errors:err_msgs})
    }else if(status==200){
      console.log("RESPON ",{...response.data})

     let data=[...MemoData.data];
     data.unshift({...response.data});
      console.log(MemoData.orginal);
  let org=[...MemoData.orginal];
   org.unshift({...response.data});

   let editIndexes=[...MemoData.editindexes];
   for(var i=0;i<editIndexes.length;i++){
     editIndexes[i]++;
   }

  setMemoData({...MemoData,data:data,orginal:org,text:"",editindexes:[...editIndexes]})
   /*
      let org=[...MemoData.orginal];
      org.unshift({...response.data});
      console.log("FROOM ",data)
    
      let editIndexes=[...MemoData.editindexes];
      for(var i=0;i<editIndexes.length;i++){
        editIndexes[i]++;
      }

      setMemoData({...MemoData,data:data,editindexes:[...editIndexes],orginal:org})*/


    }
  });

}
const tryToSaveIndex=(index)=>{

let url="notes/"+MemoData.data[index].id;


apiCall.ADD(url, {note:MemoData.data[index].note},function(status,err_msgs,response){
   
      if(status==400){
  
        //setFolderData({...FolderData,errors:err_msgs})
      }else if(status==200){
  
       console.log("R",response);
      //	setUserData({...UserData,"edit":false});
        ///apiCall.GET("users");
      }
      let data=[...MemoData.data];
      data[index].note=response.data.note;
      let editIndexes=[...MemoData.editindexes];
      if(MemoData.editindexes.includes(index)){

       
          var removeIndex=editIndexes.indexOf(index);
          editIndexes.splice(removeIndex,1);

      }
      console.log("SET DATA");
      setMemoData({...MemoData,data:data,editindexes:[...editIndexes],orginal:[...JSON.parse(JSON.stringify(data))]})



  
    }).then(() => {
      //apiCall.GET('destinations');
      
    });
}
const editOrSaveIndex=(index)=>{
 

  if(MemoData.editindexes.includes(index)){
    
    //setMemoData({...MemoData,"editindex":-1});
    tryToSaveIndex(index);
  }else{
    setMemoData({...MemoData,"editindexes":[...MemoData.editindexes,index]})
  }
  

};
console.log("MEMO",MemoData);
const cancelEdit=(index)=>{

let editIndexes=[...MemoData.editindexes];
var removeIndex=editIndexes.indexOf(index);
editIndexes.splice(removeIndex,1);

  
 let value=(MemoData.orginal[index].note);
 let data=[...MemoData.data];
 data[index]={...data[index],"note":value};
 setMemoData({...MemoData,"editindexes":[...editIndexes],data:data})


};
console.log("MEMO DATANI", MemoData);
 const memos= MemoData.data.map((item,index) =>{

	return <div key={index}><InputText label="Teksti" index={index} useAsTextArea={true} onChange={changeNoteText} data={item} name="note" edit={MemoData.editindexes.includes(index)} />
 
 
 {MemoData.editindexes.includes(index)?<div className="memobutton2"><span onClick={(e)=>{cancelEdit(index)}}>Peruuta</span></div>:null}


 <div className="memobutton"><span onClick={(e)=>{editOrSaveIndex(index)}}>{MemoData.editindexes.includes(index)?"Tallenna":"Muokkaa"}</span></div>

 
 </div>;
  
  });


 const submit=()=>{
  if(MemoData.text.trim().length!=0){
     addNew();
  }

 };
	return (<div className="mycontent memos">
			
			
    <div className="inner">
        <div className="box"><h2>Memo</h2>
        <div className="textarearow">
       
        <InputText label="Teksti" useAsTextArea={true} onChange={changeText} data={MemoData} name="text" edit={true} />
       <button onClick={submit} className={(MemoData.text.trim().length>0?"":"disabled")}>Tallenna</button>
       
       </div>
       <div id="memos">{memos}</div>
       
        </div>


        </div> </div>);
}
