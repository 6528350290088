import React,{useState,useEffect} from "react";
import { useStore } from "../../store/useStore";

import axios from "axios";
import { config } from "../../config";
import InputText from "./InputText.js";
import InputBoolean from "./InputBoolean.js";



import InputDouble from "./InputDouble.js";
import { apiClient } from "../../apiCall";


import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";



  export const defaultmystate={
    edit:false,
  
    chart:null,
    orginal:null,
    loading:true
  };
  

export default function BonusChartEditor(props) {


    const { state, dispatch } = useStore();
    const apiCall = apiClient(state, dispatch);		

    let { id } = useParams();

    const {common}=state;
   // const [mystate,setMystate]=useState({...defaultmystate,chart:props.data,orginal:{...JSON.parse(JSON.stringify(props.data))}})
   const [mystate,setMystate]=useState({...defaultmystate})
 
   
    useEffect(() => {


	    let url="bonuscharts/"+id+"";
		///let data={...WageData};
       
		console.log("LOAD",id);
       
		apiCall.GET_RAW(url, function(status,err_msgs=null,response=null){

	
			if(status==400){
		
				//setFolderData({...FolderData,errors:err_msgs})
			}else if(status==200){
				//console.log("CALC DATA",response.data);

       setMystate({...mystate,loading:false,chart:response.data,orginal:{...JSON.parse(JSON.stringify(response.data))}})

				//setData({...response.data,loading:false,simple:true});
			}




		});
	

	  }, [id]);

    const tryToSave=()=>{

      let url="bonuscharts/"+mystate.chart.id+"";
      let data={...mystate.chart};
        
      apiCall.ADD(url, data,function(status,err_msgs=null,response){
     
        if(status==400){
    
          //setFolderData({...FolderData,errors:err_msgs})
        }else if(status==200){
         
          //setAsetuksetData({...AsetuksetData,"edit":false});
          apiCall.GET("common");
         setMystate({...mystate,loading:false,edit:false,chart:response.data,orginal:{...JSON.parse(JSON.stringify(response.data))}})

          ///apiCall.GET("users");
        }
    
      }).then(() => {
        //apiCall.GET('destinations');
        
      });
    
    
    
    
      
    };

    const updateText=(name,value)=>{
      let chart=mystate.chart;
      setMystate({...mystate,chart:{...chart,[name]:value}});

    };
   if(mystate.loading){
       return (<div>ladataan</div>);
   }

   const removeBonus=(index)=>{

      let bonuschart=[...mystate.chart.chart];
     bonuschart.splice(index,1);

        let mchart={...mystate.chart};

      


        mchart.chart=[...bonuschart] ;
        
        setMystate({...mystate,chart:{...mchart}});


   
   };
    const changeBonusValue=(  name,value,index)=>{
    

     let bonuschart=[...mystate.chart.chart];
      bonuschart[index][name]=value;

        let mchart={...mystate.chart};
        mchart.chart=[...bonuschart]    ;
        setMystate({...mystate,chart:{...mchart}});
   

     };



     const addNewBonus=(target)=>{
        let bonuschart=[{ilmoitettu_myynti_limit:0,bonus:0 },...mystate.chart.chart];
        let mchart={...mystate.chart};
        mchart.chart=[...bonuschart] 
        setMystate({...mystate,chart:{...mchart}});

    } /**/
    console.log( mystate.chart.chart);
const bonus= mystate.chart.chart.map((item,index) =>{

  return <tr key={index}>
    <td><InputDouble  onChange={changeBonusValue} data={item} index={index} name="ilmoitettu_myynti_limit" edit={mystate.edit}/></td>
    <td><InputDouble targetkey="bonuschart" onChange={changeBonusValue} data={item} index={index} name="bonus" edit={mystate.edit}/></td>
    {mystate.edit?<td onClick={(e)=>removeBonus(index)}>Poista</td>:null}
    </tr>;

});







const changeEditMode=(e)=>{
  if(!mystate.edit){
    setMystate({...mystate,"edit":true});
  }else{


    console.log("ORG",JSON.stringify(mystate.orginal));
    setMystate({...mystate,edit:false,chart:JSON.parse(JSON.stringify(mystate.orginal))});



  }

};
  
const changeBoolean=(name,value)=>{


};

	return (<div className="content asetukset">

    


    <div className="inner">
    
     
      <div className="box">

      <div className="topicbar with-edit">
      <h2><InputText data={mystate.chart} name="name" onChange={updateText} edit={mystate.edit}/>
     
      
     </h2>
       <div className="right-buttons">
       {mystate.edit?
						<div className="edit-btn" onClick={(e) => {
      				  addNewBonus("bonuschart");
   				 }}><span>+</span></div>:null}

    {mystate.edit?<div className="save-btn" onClick={tryToSave}><span>Tallenna</span></div>:null}
    <div className={"edit-btn "+(mystate.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Muokkaa</span></div>
       </div>     
 
  </div>  



  <div className="description"><InputText data={mystate.chart} name="desciption" onChange={updateText} edit={mystate.edit}/></div>  
<div className="confs">



</div>
        
     


          
     
      <table className="table"><thead><tr><th>Raja</th><th>Bonus</th></tr></thead>
      <tbody>{bonus}</tbody>
        </table>
        </div>

        

      </div>
      
      </div>);
}
