import React,{useState,useEffect} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import {format} from "../commonFunctions";
import SingleBonus from "./SingleBonus";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link,
    Nav,NavLink
	// etc.
  } from "react-router-dom";

export default function AllBonuses(props) {
	const { state, dispatch } = useStore();

    

    

	
   /*const items= Object.keys(props.data.persons).map((key,index) =>{

        return (<tr key={index} className={(Math.sign(props.data.persons[key].ilmoitettu_myynti)>0?"positive":(Math.sign(props.data.persons[key].ilmoitettu_myynti)<0?"negative":"gray"))} ><td>{props.data.persons[key].name}</td><td></td><td  className="amount">{format(props.data.persons[key].ilmoitettu_myynti)} €</td></tr>:null);
      
      });

      let sum=0.0;
        for(var i in props.data){

            if(props.data[i].type===props.key_name){

                        sum+=props.data[i].value;
            } 

        }*/

        const bonuses=props.data.map((bonus,index) =>{

            return (<div key={index}><SingleBonus data={bonus} /></div>)

        });
        
 return (<div>{bonuses}</div>);
/*
	return (
		<div className=" preview">

		    <h3>Bonus osaston myynnistä</h3>
     
			<table className="table">
                <thead>

                    <tr><th>Tyyppi</th><th>Selite</th><th>Määrä</th></tr>
                </thead>
                <tbody>

                    {items}
                   
                    <tr className="total-row"><td colSpan="2">Yhteensä</td><td>{format(props.data.total)} €</td></tr>
                    <tr className="total-row"><td colSpan="2">Bonus</td><td>{format(props.data.value)} €</td></tr>

                </tbody>

            </table>
			
		</div>
	);*/
    
}
