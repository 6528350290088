import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";

import { config } from "../config";



import { apiClient } from "../apiCall";

import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";



  export const defaulLoansData={

	loaded:false,
	
	data:null,

	
};



export default function YritysLainatAll() {
	const { state, dispatch } = useStore();
   
  
    const [LoansData, setLoansData] = useState({
		...defaulLoansData
		
    });



	/*const sortData=(data)=>{


		
		data.settings.sort((a, b) => Date.parse(a.effective_from) < Date.parse(b.effective_from) ? 1 : -1)


		return data;

	};*/

    useEffect(() => {
		fetch( config.apiUrl+"/loans",{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })}).then((res) => res.json())
		  .then(data => setLoansData({data:(data),loaded:true}))

	  }, []);

	  if(!state.companies.companies){

		return null;
	}
	if(!state.common){

		return null;
	}
	if(LoansData.loaded===false){

		return null;
	}
	const keyCount=5;
	const getSorting=(a,b)=>{

		let av=0;
		let bv=0;



		if(state.common.sortIndexYritysLainat=="role"){


if(a.current_role && a.current_role.role){

	av= a.current_role.role.name;
}else{

	av="-";
}
if(b.current_role && b.current_role.role){

	bv= b.current_role.role.name;
}else{

	bv="-";
}


		}else{



console.log("sort",state.common.sortIndexYritysLainat);
var sp=state.common.sortIndexYritysLainat.split(".");

		if(sp.length==2){

			av= a[sp[1]];
			bv=b[sp[1]];
		}else{
			av= a[state.common.sortIndexYritysLainat].toUpperCase();
			bv=b[state.common.sortIndexYritysLainat].toUpperCase();
			
		}


		
		}
		
		
		console.log(state.common.sortIndexMode);
			let v= av> bv ? 1 : -1;
			return v*(state.common.sortIndexMode==="asc"?1:-1);
	

		
		//return a[state.common.sortIndexPersons].toUpperCase() > b[state.common.sortIndexPersons].toUpperCase() ? -1 : 1;
		

	};
	const headerKeys=[
		["lastname","Nimi"],
		["role","Rooli"],
		["loan_payments.orginal_loans_amount","Lainojen määrä"],
		["loan_payments.total_loans","Takaisin maksujen määrä"],
		["loan_payments.paid_total","Maksettu"],
		["loan_payments.get_balance","Lainoja jäljellä"],
		[null,"Info"]
	];
	const setOrdering=(order)=>{

		if(order){

			//alert(order);
			let mode="asc";
			if(state.common.sortIndexYritysLainat===order){

				mode=(state.common.sortIndexMode==="asc"?"desc":"asc");
			}

			

			dispatch({type:"COMMON_SET_ORDER_YRITYSLAINAT",sortIndexYritysLainat:order,sortIndexMode:mode});
		}

	};
	const getHeader=()=>{

		return headerKeys.map((item,index) =>

		<th onClick={(e) => {
			setOrdering(item[0]);
		}} className={item[0]?"orderable "+(item[0]===state.common.sortIndexYritysLainat?"order-active "+state.common.sortIndexMode:null):null} key={index}>{item[1]}</th>
		
		
		  );


	};

	const userHeader=<tr className="text-header">{getHeader()}</tr>;




	const getTyonTekijat=(company_id)=>{

		
		return LoansData.data.sort((a, b) => getSorting(a,b)).filter(person => person.department_id===company_id).map((item) =>
		<tr className="data-row" key={item.id}><td><Link to={"/persons/"+item.id+"/loan"}>{item.lastname} {item.firstname}</Link></td>
		
		<td><Link to={"/persons/"+item.id+"/loan"}>{((item.current_role && item.current_role.role)?item.current_role.role.name:"-")}</Link></td>
		
		<td>{item.loan_payments.orginal_loans_amount} €</td>
		<td>{item.loan_payments.total_loans} €</td>
		<td>{item.loan_payments.paid_total} €</td>
		<td>{item.loan_payments.get_balance} €</td>
		<td></td>
		
		</tr>
		  );




	};

	  const departments=state.companies.companies.map((item) =>
		

		<tbody key={item.id} className={item.style}>
			<tr className="header"><th colSpan={keyCount}>
				
				<h2><span className="small-info">Yritys</span>{item.name}</h2>
				
				
				</th></tr>
				{userHeader}
			{getTyonTekijat(item.id)}
		</tbody>


	  );



console.log(LoansData);

	return (

		<div className="mycontent">
			
			<div className="inner">
				
				<div className="box">
			
		<table className="table-new">
				
				{departments}


					
				
		</table></div>
		</div></div>	
	);
}
