import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import BonusChartEditor from "./form/BonusChartEditor.js" 
import InputText from "./form/InputText.js";
import { apiClient } from "../apiCall";

import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";

  export const defaultNewBonus = {
    loading:true,
    name:null,
    desciption:null,
    style_id:null,
    storing:false
    
   
  };


export default function BonusTable(props) {


	const { state, dispatch } = useStore();
  const apiCall = apiClient(state, dispatch);		

       const [active,setActive]= useState(false);
       const [newbonus,setNewbonus]= useState({...defaultNewBonus});

        if(!state.common || state.common.loading){
            return null;
        }

      
        //bonusomatalaiset.map((item,index) =>{ 

        if(!state.common || state.common.loading){
          return null;
        }
           const table=state.common.bonuschart_alaiset.map((item,index)=>{

            return <tr  className="data-row" key={item.id}>
              <td><Link to={("/bonuses/"+item.id)}>{item.name}</Link></td>
              <td><Link to={"/bonuses/"+item.id}>{item.desciption}</Link></td>
              <td><Link to={"/bonuses/"+item.id}>{item.style.name}</Link></td>
              
              
              </tr>
            
          });

          const updateValue=(name,value)=>{
          
            setNewbonus({...newbonus,[name]:value});
      
          };

const changeStyle=(e)=>{


setNewbonus({...newbonus,style_id:e.target.value});
};


  const tryToSave=(e)=>{

    let url="bonuscharts/";
    let data={...newbonus};
      
    apiCall.ADD(url, data,function(status,err_msgs=null,response){
   
      if(status==400){
  
        //setFolderData({...FolderData,errors:err_msgs})
      }else if(status==200){
       
        //setAsetuksetData({...AsetuksetData,"edit":false});
        apiCall.GET("common");
        setActive(false);
        setNewbonus({...defaultNewBonus});
       //setMystate({...mystate,loading:false,chart:response.data,orginal:{...JSON.parse(JSON.stringify(response.data))}})

        ///apiCall.GET("users");
      }
  
    }).then(() => {
      //apiCall.GET('destinations');
      
    });


};

const empty=<option key="0" value="0">Valitse tyyli</option>




console.log(state.common);
          const options=state.common.bonus_styles.map((option) =>
		<option key={option.id} value={option.id}>{option.name}</option>
		);
    const select=(<div>
          
      <select onChange={(e) => {changeStyle(e)}}  value={(newbonus.style_id===null?0:newbonus.style_id)}>{empty}{options}</select>
      
      
      </div>);
        
const getNewForm=()=>{

  return (<div className="form-blocks">

<div className="form-block"><div className="left"><InputText  onChange={updateValue} label="Taulukon nimi" name="name" data={newbonus} edit={true}/></div>
<div className="right"><InputText  onChange={updateValue} label="Taulukon kuvaus" name="desciption" data={newbonus} edit={true}/></div></div>
  
<div className="form-block"><div className="left">{select}</div>
<div className="right"><div class="save-btn"><span onClick={tryToSave}>Tallenna</span></div></div>
</div>
  </div>)

};
         // const table_data=state.common.bonuschart_alaiset[active];
          
         const newform=getNewForm();
    return (	<div className="mycontent">
			
    <div className="inner">
      
      <div className="box">
    <div className="add-new-person-link-holder"><span onClick={(e)=>setActive(!active)}>Lisää taulukko</span></div>
    {active?newform:null}
  <table className="table-new">
    
  
      <tbody>
      <tr class="header"><th colspan="3"><h2><span class="small-info"></span>Bonustaulukot</h2></th></tr>

      <tr class="text-header"><th>Nimi</th><th>Kuvaus</th><th>Tyyppi</th></tr>


    {table}
        </tbody>
    
    
    
  </table></div>
  </div></div>		);

        //return (<div><div className='nav'>{nav}</div><BonusChartEditor id={table_data.id}/></div>);


}