import React,{useState,useEffect} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import {format,formatSeason} from "../commonFunctions";
import WageDisplay from "./WageDisplay.js"

import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link,
    Nav,NavLink
	// etc.
  } from "react-router-dom";

export const defaultPreviousData = {
	id:null
};
export default function PreviousWageNav(props) {

    const [Previous, setPrevious] = useState({
		...defaultPreviousData
		
    });
    if(props.items.loading){
        return (<ul><li>Loader</li></ul>)
    }
   


    const openPrevious=(id)=>{


        if(Previous.id===id){

            closePrevious();
        }else{
        setPrevious({id:id});

        }

    };

    const closePrevious=()=>{


        setPrevious({id:null});
    };

    const items= props.items.data.map((item,index) =>{


       

        const classi=(Previous.id===item.id?"active-li current":"");


        return (<li className={classi} onClick={(e) => {
            openPrevious(item.id);
        }} key={index}>{formatSeason(item.period)}{item.type==="lisa"&&<span className="lisainfo">(Lisäpalkka)</span>}</li>);
       
      });

      const older=(Previous.id!==null?<WageDisplay  id={Previous.id}/>:null);


      const mainc=(Previous.id===null?"active-li current":"active-li current-half");


    return (<div className="previouswages overflownav"><div className="leafnav"><ul><li className={mainc} onClick={(e) => {
           closePrevious();
        }}>{formatSeason(props.period)}{props.isLisapalkka&&<span className="lisainfo">(Lisäpalkka)</span>}</li><li className="nav"><ul>{items}</ul></li></ul></div>{older}</div>)
}