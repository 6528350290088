import React,{createRef,useState} from "react";
import Picker from 'react-month-picker';



export const defaultUI={
   year:null,
   show:false
};


export default function Period(props) {

   

    const [UI, setUI] = useState({
        ...defaultUI
       
        
    });



    const pickerLang = {
        months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        from: 'From', to: 'To',
    }
    //const season={year: 2021, month: 4};
   
    let date="(Ei arvoa)";

   
   let dates=[];
   var season={year:null,month:null};
   if(props.value){
     dates=props.value.split("-");
   }
    if(dates.length===3){
        date=dates[1]+"/"+dates[0];
         season={year: parseInt(dates[0]), month: parseInt(dates[1])};
    }

   

   
   
  
    const pickAMonth = createRef();
    const openSelector=()=>{

        pickAMonth.current.show();

    };
    const changeValue=(y,m)=>{
        m+="";
       if(m.length==1){
           m="0"+m;
       }
        var v=y+"-"+m+"-"+"01";
      

       props.onChange(props.name,v,props.index);
       pickAMonth.current.dismiss();

    };
    /*
   
                          
                       <div onClick={openSelector}>{makeText(season)}</div>
                       */ 
let min={year: 2000, month: 1};


            if(props.min){



                let dates_min=props.min.split("-");
   
                if(dates_min.length===3){
                  min={year: parseInt(dates_min[0]), month: parseInt(dates_min[1])};
                    //date=dates[1]+"/"+dates[0];

                    if((min.month+"").length===1){
                        
                      
                    }
                }
               




            }
            let max={year: 2030, month: 12};
            if(props.max){


                let dates_max=props.max.split("-");
   
                if(dates_max.length===3){
                  max={year: parseInt(dates_max[0]), month: parseInt(dates_max[1])};
                    //date=dates[1]+"/"+dates[0];

                    if((max.month+"").length===1){
                        
                       
                    }
                }




            }

          

const getDisplayYear=()=>{
    var year=season.year;
    if(!season.year){
        year=new Date().getFullYear();
    }
return (UI.year?UI.year:year);

}

const getCalendar=(year,min,max,selected_year,selected_month)=>{

    var months=[1,2,3,4,5,6,7,8,9,10,11,12];
const canSelect=(year,month)=>{
    let cur= new Date(year+"-"+month+"-01");
    let minmonth= new Date(min.year+"-"+min.month+"-01");
    let maxmonth= new Date(max.year+"-"+max.month+"-01");
    if(cur<minmonth){
        return false;
    }
    if(cur>maxmonth){
      return false;
    }
    return true;

}
const getClass=(year,value)=>{



    let cur= new Date(year+"-"+value+"-01");

    let minmonth= new Date(min.year+"-"+min.month+"-01");
    let maxmonth= new Date(max.year+"-"+max.month+"-01");

    let classes=["month"];

    if(cur<minmonth){
        classes.push("disabled past");
    }
    if(cur>maxmonth){
        classes.push("disabled future");
    }

    
    if(selected_year==year && selected_month==value){
        classes.push("selected");
    }
    

    return classes.join(" ");

};
const previousYear=()=>{

    setUI({...UI,year:getDisplayYear()-1});

};
const nextYear=()=>{
    setUI({...UI,year:getDisplayYear()+1});

};

const reset=()=>{
    props.onChange(props.name,null,props.index);
    openHide();

}
const selectYearMonth=(year,month)=>{

    if(canSelect(year,month)){
     
        month+="";
        if(month.length==1){
            month="0"+month;
        }
        var v=year+"-"+month+"-"+"01";
       
        props.onChange(props.name,v,props.index);
        openHide();
    }


};

    const month_btns=months.map((value,index) =>

        <div  onClick={(e)=>{selectYearMonth(year,value)}} className={getClass(year,value)}>{value}</div>


    );
    console.error("REDRAW: ",min,max);

    const prev=(min.year<year?<div onClick={previousYear} className="calbtn back">◀</div>:null);
    const next=(max.year>year?<div onClick={nextYear} className="calbtn next">▶</div>:null);

/* MIN{min.year+" "+min.month}<br/>
        MAX{max.year+" "+max.month}<br/>*/ 
    return <div className="jukkacalendar">

       <div className="holder">

        {props.allowreset===true&&<div className="reset-holder"><span onClick={reset} className="tyhjenna">Tyhjennä</span></div>}
        <div className="topic-bar"><div className="left">{prev}</div><h4>{year}</h4><div className="right">{next}</div></div>
       


            <div className="months">
                {month_btns}
            </div>
        </div>
        </div>

};
const getSelectedValue=()=>{
    if(season.year && season.month){
        return (season.month+" / "+season.year);
    }
    return "(Ei valintaa)";

}
const openHide=()=>{
 
    if(!props.edit){
return;
    }
    setUI({...UI,show:!UI.show})
}

return <div className="jkcal-block">
    {UI.show && <div onClick={openHide} className="fixed-hide"></div>}
    <p onClick={openHide} className="selected-date">{getSelectedValue()}</p>
    {UI.show && getCalendar(getDisplayYear(),min,max,season.year,season.month)}

</div>;







	return (
        <div>
     <Picker value={season}  years={{min: min, max: max}} onChange={changeValue} lang={pickerLang.months} ref={pickAMonth} />

     
    <div className="period" 
    
    onClick={(e) => {
        
        e.preventDefault();
        e.stopPropagation();
        //tryLogin();
        if(props.edit){
            openSelector();
        }
    }}
    
    >{date}
    (
   {min.year}-{min.month},{max.year}-{max.month})
    </div></div>);
}
