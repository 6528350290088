import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputText from "./form/InputText.js";
import InputSelect from "./form/InputSelect.js";
import SideNavigation from "./SideNavigation";

import SettingPair from "./form/SettingPair.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";

import InputDouble from "./form/InputDouble.js";
import TopNav from "./TopNav.js";
import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";



 

export default function Etusivu(props) {

    return ( <div className="frontpage"><SideNavigation is_frontpage={true} /></div>);
}   