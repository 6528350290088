import React,{useState,useEffect} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import InputDouble from "./form/InputDouble.js";
import TopNav from "./TopNav.js";
import Period from "./form/Period.js";
import Notify from "./Notify.js";

import InputBoolean from "./form/InputBoolean";
import InputSelect from "./form/InputSelect";
import {format} from "../commonFunctions";

import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";
  export const defaultLaskentaData = {
	loading:true,
	departments:[],
	period:null,
	period2:null,
	filterCompany:null,
	headers:[],
	showfilters:false,
	company_filters:[],
	isPalkkaLista:false,
	selected_person:null,
	
};
export default function PalkkalaskelmatHenkilot() {
	const { state, dispatch } = useStore();
	const {common}=state;
  
	

	const [LaskentaData, setLaskentaData] = useState({
		...defaultLaskentaData
		
    });


	const headerKeys=[
		["period","Kausi"],
       
		["brutto","Brutto"],
		["nettopalkka","Netto"],
		["sairasloma_lkm","Sairaslomat"],
		["ennakonpidatyksenalainen","Ennakonpidätyksen alainen"],
		["ennakonpidatys","Ennakonpidätys"],
		["maksetaan","Maksetaan"],
		["ilmoitettu_myynti","Ilmoitettu myynti"],
		["laskutettu_myynti","Laskutettu myynti"],
		["paivarahat","Päivärahat"],
		["kilometrit","Kilometrit"],
		["tyottomyysvakuutusmaksu","T.vak"],
		["tyel","TyEL"],
		["ulosotto","Ulosotto"]

		
	];


	const apiResponse=(data)=>{

		

	
		
	
	
		if(data){
			
			
			
			setLaskentaData({...LaskentaData,departments:[...data]});
			
		}


	  };
	  const getPeriod=()=>{

		
	/*	if(common.current_period==null){ return null; }




		if(state.ui.period==null){
			return common.current_period;


		}*/

		return state.ui.reportingperiod1;

	  }
	  const getPeriod2=(start)=>{

		/*if(common.current_period==null){ return null; }
		

		console.log("STATE UI PERIOD 2",state.ui.period2);
		if(state.ui.period2==null){
			return common.current_period;


		}
		console.log("IS PERIOD 2 smaller than start ",(new Date(state.ui.period2)<new Date(start)));
		if(new Date(state.ui.period2)<new Date(start)){
			return start;
		}*/


		return state.ui.reportingperiod2;
		

	  }

const period=getPeriod();
const period2=getPeriod2();
console.log("RETURNED PERIOD2",period2)
const person=LaskentaData.selected_person;

useEffect(() => {


  
	var k=[];
	for(var i in headerKeys){
		k.push(headerKeys[i][0]);
	}

    if(state.users.items && state.users.items.length>0){
	

	setLaskentaData({...LaskentaData,headers:k,selected_person: state.users.items[0].id});

    }





},[state.users.items]);
	useEffect(() => {
		
       
		if(period==null){ return; }

        if(person==null || person==0){ return; }
		
		var url="/palkkalaskelmat/"+period+"/"+period2;
		
		if(person && person!=0){

			url="/palkkalaskelmatperson/"+person+"/"+period+"/"+period2;


		}
	

		fetch( config.apiUrl+url,{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data =>apiResponse(data))

	 
		

	  }, [period,period2,person]);


	 


	
	if(!state.users.items){
		return null;
	}
	const getPalkkaLink=(item)=>{

		if(item.wages.length==0){

			return <Link className="arrow-right" to={"/palkanlaskenta/"+item.person.id+"/"+period} >Aloita laskenta</Link>
		}


		if(item.wages.length==1){
			if(item.wages[0].confirmed===1){
				return <Link className="arrow-right" to={"/palkanlaskenta/"+item.person.id+"/"+period} >Valmis</Link>
			}else{

				
				return <Link className="arrow-right" to={"/palkanlaskenta/"+item.person.id+"/"+period} >Kesken <Notify errors={item.wages[0].calculation.errors.value}/></Link>
			}
			
		}
		return  null;
	}
	const getVar=(item,key)=>{
		if(item.confirmed===1){


			return (item[key]!==0?format(item[key]):"-");


		}else{

			return format(item.calculation[key].value);
		}

		
	}
    const formatdate=(period)=>{
        let dates=[]
        if(period){
          dates=period.split("-");
        }
         if(dates.length===3){
            return dates[1]+"/"+dates[0];
         }
         return null;


    };
	const getValue=(key,item,round=true)=>{

		
		switch(key){

			case "sairasloma_lkm":
			return item[key];
			break;
			case "period":
               
                 return formatdate(item[key]);
             
            break;
			case "person_name":
				return (item[key]);
			break;
			default:
				if(round){
					return format(item[key]);
				}else{

					return (item[key]);
				}
				
			break;
		}
	}
	const showColumn=(type)=>{
	
		if(LaskentaData.headers.length==0){
			return true;
		}
		return LaskentaData.headers.includes(type);
	};
	const getRow=(item,index)=>{


		



	const columns=headerKeys.map((header,index_header) =>

		(showColumn(header[0])?<td  >{getValue(header[0],item)}</td>:null)


  );
  return <tr className="data-row" key={"row-"+index}>{columns}</tr>


	

	};
	
	
	


	const changePeriod=(d,v)=>{

		/*if(LaskentaData.isPalkkaLista){
			dispatch({ type: "CHANGE_PERIOD", period: v });
			dispatch({ type: "CHANGE_PERIOD2", period2: v });
		}else{*/
			dispatch({ type: "CHANGE_REPORTING_PERIOD_1", period: v });

		//}
		
		//setLaskentaData({...LaskentaData,period:v});
		
		
	};
	const changePeriod2=(d,v)=>{

		dispatch({ type: "CHANGE_REPORTING_PERIOD_2", period: v });
		//setLaskentaData({...LaskentaData,period:v});
		
		
	};	

	if(!period){

		return (<div>Ladataan</div>);
	}
	if(!state.companies.companies){

		return null;
	}
	
	/*let companies=[];
	for(var i=0;i<LaskentaData.data.length;i++){
		console.log(LaskentaData.data[i]);
		if(companies[LaskentaData.data[i].person.department_id]==null){
			companies[LaskentaData.data[i].person.department_id]=[];
		}
		companies[LaskentaData.data[i].person.department_id].push(LaskentaData.data[i]);
		
	}
	companies = companies.filter(function (el) {
		return el != null;
	  });
	  const listItems2 = companies.map((item,index) =>
		getCompany(item,index)
	);*/

	
	
	const setOrdering=(v)=>{

		if(state.common.sortIndexPalkanlaskenta==v){
			
			if(state.common.sortIndexModePalkanlaskenta=="asc"){

				dispatch({ type: "SORT_PALKANLASKENTA",sortIndexPalkanlaskenta:state.common.sortIndexPalkanlaskenta, sortIndexModePalkanlaskenta: "desc" });

			}else{
				dispatch({ type: "SORT_PALKANLASKENTA",sortIndexPalkanlaskenta:state.common.sortIndexPalkanlaskenta, sortIndexModePalkanlaskenta: "asc" });

			}
		}else{

			dispatch({ type: "SORT_PALKANLASKENTA", sortIndexPalkanlaskenta: v });

		}
		/*alert(e);

alert(state.common.sortIndexMode);*/

	};
	
	const getHeader=()=>{


		
		return headerKeys.map((item,index) =>
		(showColumn(item[0])?
		<th onClick={(e) => {
			setOrdering(item[0]);
		}} className={item[0]?"orderable "+(item[0]===state.common.sortIndexPalkanlaskenta?"order-active "+state.common.sortIndexModePalkanlaskenta:null):null} key={index}>{item[1]}</th>
		
		:null)
		  );


	};

	const userHeader=<tr className="text-header">{getHeader()}</tr>;
	const editFilters=(name,value)=>{

		
		if(LaskentaData.headers.includes(name)){


			var d=[...LaskentaData.headers];
			d= d.filter(function(value, index, arr){ 
				return value!=name
			});
			setLaskentaData({...LaskentaData,headers:[...d]});

		}else{

			var d=[...LaskentaData.headers];
			d.push(name);
			setLaskentaData({...LaskentaData,headers:[...d]});

		}
	};
	const getFilters=()=>{


		
		
		return headerKeys.map(function(item){

			var k=item[0];
			
			var v={[k]:(showColumn(item[0])?1:0)};
		
			return <div><InputBoolean onChange={editFilters} value={item[0]} label={item[1]} edit={true} name={item[0]} data={v}/></div>
		
		
		});


	};

	const filternav=getFilters();
	/*const filternav=headerKeys.map((item,index)=>
	 	<InputBoolean name={item[0]} />

	);*/ 
const palkat=null;
const showFilters=(e)=>{

	setLaskentaData({...LaskentaData,showfilters:!LaskentaData.showfilters})
}
const preFilters=(type,asReturn=false)=>{


	var d=[];

	switch(type){
		case "palkkaraportti":
			d=["period","brutto","nettopalkka","maksetaan"];
		break;
		case "myyntiraportti":
			d=["period","ilmoitettu_myynti","laskutettu_myynti"];
		break;
		case "palkkalista":
			d=["period","brutto","nettopalkka","maksetaan","ennakonpidatyksenalainen","ennakonpidatys","paivarahat","kilometrit","tyottomyysvakuutusmaksu","tyel"];
		break;
	}
	

	
	if(asReturn){
		return d;
	}		
	setLaskentaData({...LaskentaData,headers:[...d]});

};
/*	const headerKeys=[
		["person_name","Nimi"],
       
		["brutto","Brutto"],
		["nettopalkka","Netto"],
		["ennakonpidatyksenalainen","Ennakonpidätyksenalainen"],
		["ennakonpidatys","Ennakonpidätys"],
		["maksetaan","Maksetaan"],
		["ilmoitettu_myynti","Ilmoitettu myynti"],
		["laskutettu_myynti","Laskutettu myynti"],
		["paivarahat","Päivärahat"],
		["kilometrit","Kilometrit"],
		["tyottomyysvakuutusmaksu","T.vak"],
		["tyel","TyEL"],
		["ulosotto","Ulosotto"]

		
	];*/ 



const getSum=(key,palkat)=>{

 
if(key=="period"){

		return null;
	}

    if(key=="person_name"){

		return null;
	}


	let sum=0.0;
	for(var i=0;i<palkat.length;i++){
		if(key=="sairasloma_lkm"){
			sum+=parseInt(palkat[i][key]);
		}else{
			sum+=palkat[i][key];
		}
		
	}

	if(key=="sairasloma_lkm"){
		return sum;
	}

	return format(sum);
}
const sumrow=(palkat)=>{

	const columns=headerKeys.map((header,index_header) =>

		(showColumn(header[0])?<td  >{getSum(header[0],palkat)}</td>:null)


  );
  return <tr className="data-row data-totals">{columns}</tr>


}
//const totals=sumrow();
const preClasses=(mode)=>{


	var req_filter=preFilters(mode,true);
	if(LaskentaData.headers.length!=req_filter.length){
		return "";
	}
	
	for(var i=0;i<req_filter.length;i++){
			let f=req_filter[i];

			if(!LaskentaData.headers.includes(f)){
				return "";
			}

	}

	return " active";
}

const setAsPalkkaLista=()=>{


	var req_filter=preFilters("palkkalista",true);
	setLaskentaData({...LaskentaData,isPalkkaLista:true,headers:[...req_filter]})
	//changePeriod2(null,getPeriod());



}
const prefilters=<div className="prefilters">
	<div className={"pre"+preClasses("palkkaraportti")} onClick={e=>preFilters("palkkaraportti")}>Palkkaraportti</div>
	<div className={"pre"+preClasses("myyntiraportti")} onClick={e=>preFilters("myyntiraportti")}>Myyntiraportti</div>
	<div className={"pre"+preClasses("palkkalista")} onClick={e=>preFilters("palkkalista")}>Palkkalista</div>


</div>;

const periodText=()=>{


    var p1=formatdate(period);
    var p2=formatdate(period2);
    if(p1==p2){
        return p1;
    }
    return p1+" - "+p2;
}

const listItems =(palkat) =>{

	return palkat.map((item,index) =>
	getRow(item,index)
	)

} 

const dpdata=()=>{


/*
let deps=[];

if(LaskentaData.company_filters.length==0){
		
	deps=[...LaskentaData.departments];

}else{

	for(var i=0;i<LaskentaData.departments.length;i++){

	

		if(LaskentaData.company_filters.includes(LaskentaData.departments[i].id)){

			deps.push(LaskentaData.departments[i]);
		}

	}
}*/

const deps=[...LaskentaData.departments];

	return deps.map((item,index)=>

	
	<tbody>
		
		<tr className="header"><th colSpan={headerKeys.length}>
				
				<h2><span className="small-info">{periodText()}</span>{item.name}</h2>
				
				
				</th></tr>
		
		{userHeader}
	
	
	
	{listItems(item.wages)}{sumrow(item.wages)}</tbody>

	);

	/*const listItems = palkatData.map((item,index) =>
		getRow(item,index)
	);*/

};



const selectCompany=(id)=>{


let sel=[];
	if(LaskentaData.company_filters.length==0){
		//valitaan kaikki paitsi tämä
		for(var i=0;i<state.companies.companies.length;i++){
			if(state.companies.companies[i].id!=id){
				sel.push(state.companies.companies[i].id);
			}
		}
		
	}else{

		if(LaskentaData.company_filters.includes(id)){
			//pois
			if(LaskentaData.company_filters.length==1){
				return;
			}

			sel=[...LaskentaData.company_filters];
			sel = sel.filter(function(value, index){ 
				return value !=id;
			});


		}else{

			sel=[...LaskentaData.company_filters,id];
			
		}



	}
	setLaskentaData({...LaskentaData,company_filters:sel})
};

const company_filters=state.companies.companies.map((item) =>{

	return <div onClick={e=>selectCompany(item.id)} className={"company"+((LaskentaData.company_filters.length==0 || LaskentaData.company_filters.includes(item.id))?" active":"")}>{item.name}</div>

});
const changePerson=(n,v)=>{
	setLaskentaData({...LaskentaData,[n]:v});

};
const getSelectPersonFilter=()=>{
	const persons=state.users.items.map(person=>(


		{id:person.id,name:person.lastname+" "+person.firstname}
		
		));

	return <InputSelect  onChange={changePerson} zero="--Valitse henkilö--" options={persons} data={LaskentaData} name="selected_person" edit={true}/>;
};



console.log("MINIMI ARVO VASEN: ",state.common.min_wage_period);
console.log("MAKSIMI ARVO VASEN: ",period2);

console.log("MINIMI ARVO OIKEA: ",period);
console.log("MAKSIMI ARVO OIKEA: ",state.common.max_wage_period);
console.log("ARVO OIKEA NYT",period2);

	return (
		<div className="mycontent raporttilaskelmat">
			


		<div className="inner">

		<div className="period-topic topic-box no-print">
					<div className="inner">
					<h2>Raportit</h2>
					<Period min={state.common.min_wage_period} max={period2} onChange={changePeriod} name="period"  value={period} edit={true}/>
					{true?<span> - </span>:null}
					{true?<Period min={period} max={state.common.max_wage_period} onChange={changePeriod2} name="period2"  value={period2} edit={true}/>:null}
					
                    <div>{getSelectPersonFilter()}</div>
                    
                    <div className="filter-button"><div className={"edit-btn"+(LaskentaData.showfilters?" active":"")} onClick={e=>showFilters()}><span>Näytä filtterit</span></div>
					
                    
                    
                    {LaskentaData.showfilters?

					<div>
						<div onClick={e=>showFilters()} className="fixed-hide"></div>
					<div className="filters"><div className="inner-filters">{prefilters}<hr/>{(true?filternav:null)}</div></div>
					</div>
					
					:null}
					</div>
					{true?<div className="print-page"><span onClick={(e)=>{

window.print();
	}}>Tulosta</span></div>:null}
					</div>
					
		</div>
	


			
		
				
					<div className="box">
					<div className="side-scroll">
					<table className="table-new table-reports">
                        
						{dpdata()}
						

			
					</table>
					</div></div>
		</div>
		</div>	
	);
}
