import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputText from "./form/InputText.js";
import InputSelect from "./form/InputSelect.js";

import SettingPair from "./form/SettingPair.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";
import InputDouble from "./form/InputDouble.js";
import { apiClient } from "../apiCall";
import TopNav from "./TopNav.js";
import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";
import GlobalSettings from "./form/GlobalSettings";
import { getAllByPlaceholderText } from "@testing-library/dom";



  export const defaultAsetuksetData={
    edit:false,
    opens:[],
    settings:[]
  
  };
  

export default function Asetukset(props) {


    const { state, dispatch } = useStore();
    const {common}=state;

 
    const [AsetuksetData, setAsetuksetData] = useState({
      ...defaultAsetuksetData
      
      });
      const apiCall = apiClient(state, dispatch);
      useEffect(() => {
        if(common.globalsettings){

          let settings=JSON.parse(JSON.stringify(common.globalsettings));

          for(var i=0;i<settings.length;i++){
            settings[i].values.sort((a, b) => Date.parse(a.effective_from) < Date.parse(b.effective_from) ? 1 : -1);
          }

         
        /*  for(var i=0;i<settings.length;i++){
            settings[i].values.sort((a, b) => Date.parse(a.effective_from) < Date.parse(b.effective_from) ? 1 : -1);
          }*/


          
          setAsetuksetData({...AsetuksetData,settings:settings});
        }
        }, 
        
        [common]);
    


    if(!state.common.globalsettings){
        return null;
    }

    const openClose=(id)=>{

      if(AsetuksetData.opens.includes(id)){

        let opens = AsetuksetData.opens.filter(function(value, index, arr){ 
          return id !== value;
      });

      setAsetuksetData({...AsetuksetData,"opens":opens});

      }else{
        let opens=[...AsetuksetData.opens,id];

        setAsetuksetData({...AsetuksetData,"opens":opens});


      }


    };
   
   
    
    

 

const tryToSave=()=>{

	let url="settings";
	let data={...AsetuksetData};
	
	apiCall.ADD(url, data,function(status,err_msgs=null){

		if(status==400){

			//setFolderData({...FolderData,errors:err_msgs})
		}else if(status==200){

      setAsetuksetData({...AsetuksetData,"edit":false});
			apiCall.GET("common");
		//	setUserData({...UserData,"edit":false});
			///apiCall.GET("users");
		}

	}).then(() => {
		//apiCall.GET('destinations');
		
	});




	
};

const addNewSettings=(settings_id)=>{

    let settings_index=getAsetusIndex(settings_id);
    let n={id:null,value:0,effective_from:common.current_period};
    let values=[...AsetuksetData.settings[settings_index].values,n];

    
    values.sort((a, b) => Date.parse(a.effective_from) < Date.parse(b.effective_from) ? 1 : -1);



    let asetus={...AsetuksetData.settings[settings_index],values:[...values]}

    let newsettings=[...AsetuksetData.settings];
    newsettings[settings_index]=asetus;


    setAsetuksetData({...AsetuksetData,
      settings:[...newsettings]
      
    });




};
const getAsetusIndex=(settings_id)=>{



  
  for(var i=0;i<state.common.globalsettings.length;i++){
    if(settings_id===state.common.globalsettings[i].id){
      
      

          return i;
          
      

       
    }

  }


  return false;


  };

const onRemove=(settings_id,index)=>{

  let settings_index=getAsetusIndex(settings_id);
 

  let settingvalues=[...AsetuksetData.settings[settings_index].values];

  settingvalues.splice(index,1);
  let asetus={...AsetuksetData.settings[settings_index],values:settingvalues}


  
  let allsettings=[...AsetuksetData.settings];
  allsettings[settings_index]=asetus;

  setAsetuksetData({...AsetuksetData,
    settings:[...allsettings]
    
  });



}

const paivitaAsetus=(settings_id,name,value,index)=>{

  let settings_index=getAsetusIndex(settings_id);
  let settingvalue={...AsetuksetData.settings[settings_index].values[index]};
  settingvalue[name]=value;

  let values=[...AsetuksetData.settings[settings_index].values];
  values[index]={...settingvalue};
  let asetus={...AsetuksetData.settings[settings_index],values:values}

  let newsettings=[...AsetuksetData.settings];
  newsettings[settings_index]=asetus;
 // alert(asetus);
  setAsetuksetData({...AsetuksetData,
    settings:[...newsettings]
    
  });



};

const changeEditMode=(e)=>{
  if(!AsetuksetData.edit){
    setAsetuksetData({...AsetuksetData,"edit":true});
  }else{

    var settings=JSON.parse(JSON.stringify(common.globalsettings));
    for(var i=0;i<settings.length;i++){
      settings[i].values.sort((a, b) => Date.parse(a.effective_from) < Date.parse(b.effective_from) ? 1 : -1);
    }



    setAsetuksetData({...AsetuksetData,edit:false,bonuschart:JSON.parse(JSON.stringify(common.bonuschartorginal)),settings:settings});


    //let d=JSON.parse(JSON.stringify(UserData.orginal));
  // setUserData({...AsetuksetData,"edit":false,"data":{...d}});

  }

};



	return (<div className="content asetukset yleiset-asetukset">

<TopNav links={[
      {url:"/asetukset",name:"Yleiset"},
      {url:"/asetukset/bonuschart",name:"Myyjien bonustaulukko"},
      {url:"/asetukset/bonuschartosasto",name:"Osaston bonustaulukko"},
      {url:"/asetukset/bonuschartalaiset",name:"Alaisten bonustaulukko"},
      ]}/>


    <div className="inner">
    <div className="topicbar"><h2>Yleiset Asetukset</h2>
    <div className="right-buttons">
    {AsetuksetData.edit?<div className="save-btn" onClick={tryToSave}><span>Tallenna</span></div>:null}
    <div className={"edit-btn "+(AsetuksetData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Muokkaa</span></div>
       </div>     
 
</div>       
           


    <GlobalSettings addNewSettings={addNewSettings} onRemove={onRemove}  onChange={paivitaAsetus} settings={AsetuksetData.settings} edit={AsetuksetData.edit} />



     
      

       


      </div>
      
      </div>);
}
