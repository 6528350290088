import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputText from "./form/InputText.js";
import InputSelect from "./form/InputSelect.js";
import { apiClient } from "../apiCall";

import SettingPair from "./form/SettingPair.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";


import TopNav from "./TopNav.js";
import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";
  export const defaultSearchData={

	loading:false,
	results:null,
    term:null
	
	
};
  
  export default function SearchField(props) {
	
    const { state, dispatch } = useStore();
    const apiCall = apiClient(state, dispatch);	

    const [inputText, setInputText] = useState('');
    const [timer, setTimer] = useState(null);

    const [SearchData, setSearchData] = useState({
		...defaultSearchData
		
    });

	const ADM=(state.common.me.role.is_super_admin===1?true:false);
    if(!ADM){
        return null;
    }
    const queryServer=(value)=>{

        if(value.trim().length>3){
console.log("q",value);
//setSearchData({loading:true,results:null,term:value});

let url="search/"+value;
  

apiCall.GET_RAW(url, function(status,err_msgs=null,response=null){




    if(status==400){
  
    
      }else if(status==200){
        console.log("OLI 200",SearchData.term,response.data.term);
               
            
               setSearchData({...SearchData,loading:false,term:response.data.term,results:[...response.data.results]});
               
      }
  
  
  
  
    });









}

    }
    const onChange=(name,value)=>{
        setInputText(value);

        if((value.trim().length>3)){
            setSearchData({loading:false,results:null,term:value});
        }else{
            setSearchData({loading:false,results:null,term:null});
        }
        if (timer) clearTimeout(timer);
        const newTimer = setTimeout(() => {
            queryServer(value);
        }, 2000);

        setTimer(newTimer);

    }
    const clearForm=()=>{
        setInputText("");
        setSearchData({loading:false,results:null,term:null});
    };
    const getResults=()=>{
        if(SearchData.results===null){
            return null;
        }
        if(SearchData.results.length==0){
            return <p className="no-results">Ei hakutuloksia</p>
        }
        return SearchData.results.map((item,index) =>{

                return (<div className="search-item">
                    <Link to={item.link}>
                    <span className="topic_left">{item.topic_left}</span>
                    <span className="topic_right">{item.topic_right}</span>
                    <span className="title">{item.title}</span>
                    </Link>
                    </div>);
            
            });

    };
    const results=getResults();
   



    return <div className="search-field">
        <div className="search-holder">
        <InputText onChange={onChange} data={{term:inputText}} name="term" edit={true}/>
        {inputText.length!=0&&<span id="reset" onClick={(e)=>clearForm()}>Tyhjennä</span>}
        </div>
    {results&&<div id="search-results">{results}</div>}</div>

}
 