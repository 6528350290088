import React,{useState,useEffect} from "react";



export default function StatusLoader(props) {

    let content=null;
    switch (props.state){

        case 1:
            content=<span>Tallennetaan...</span>
        break;
        case 2:
            content=<span>Tallennettu</span>
        break;
        case 3:
            content=<span>Virhe</span>
        break;

    }
    return <div className={"loader-animation-holder state-"+props.state}>{content}</div>

}


