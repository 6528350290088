

import GlobalSettingTable from "./GlobalSettingTable.js";

export default function GlobalSettings(props) {

   
    const getSettingValues=(id)=>{

      return  props.settings.filter(function (item) {
            
            return item.usersettings_names_id ===id;

        });
    };

   /* const updateSettingValue=(name,value,index)=>{


        alert(name+"  "+value+"  "+index);

    };*/
   
    const items = props.settings.map((item,index) =>

    
 
       <div className="setting-section" key={index}>
         <h5>{item.name}{props.edit?
						<div className="edit-btn" onClick={(e) => {
      				  props.addNewSettings(item.id);
   				 }}><span>+</span></div>:null}</h5>
         <GlobalSettingTable  onChange=
           
           {(name,value,index) => {
            props.onChange(item.id,name,value,index);
        }}


        onRemove=
           
        {(index_child) => {
         props.onRemove(item.id,index_child);
     }}

        
           
           
          values={item.values} edit={props.edit}/>
       </div>
      


    );



	return (<div className="settings-all">{items}</div>);
}
