import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";

import { config } from "../config";

import InputDouble from "./form/InputDouble.js";
import InputText from "./form/InputText.js";
import InputBoolean from "./form/InputBoolean.js";

import Loans from "./form/Loans.js";
import LoanLyhennykset from "./form/LoanLyhennykset.js";
import PersonTopNav from "./PersonTopNav.js";
import { apiClient } from "../apiCall";
import Period from "./form/Period.js";
import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";



  export const defaultUserData={

	loaded:false,
	edit:false,
	data:null,
	orginal:null,
	errors:null
	
};


export const DefaultJatkuvaData={
	alkaa:"2023-01-01",
	paattyy:null,
	selite:null,
	summa:0,
	automatic:0
	
	
	


};
export default function PersonNettoVahennykset() {
	const { state, dispatch } = useStore();
    let { id } = useParams();
  
    const [UserData, setUserData] = useState({
		...defaultUserData
		
    });

	const apiCall = apiClient(state, dispatch);

	const sortData=(data)=>{


		
		data.settings.sort((a, b) => Date.parse(a.effective_from) < Date.parse(b.effective_from) ? 1 : -1)


		return data;

	};
	const editBoolean=(name,value,index)=>{
console.log(UserData.data.jatkuvat,index);

		let jatkuvat=[...UserData.data.jatkuvat];

		console.log(jatkuvat[index]);
		jatkuvat[index]={...jatkuvat[index],[name]:value};

		
		setUserData({...UserData,data:{
			...UserData.data,
			
			jatkuvat:jatkuvat}})



	};
    useEffect(() => {
		fetch( config.apiUrl+"/persons/"+id+"/jatkuvat",{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })}).then((res) => res.json())
		  .then(data => setUserData({data:data,orginal:JSON.parse(JSON.stringify((data))),loaded:true,errors:null}))

	  }, []);






	
	if(!state.users.items || !state.common.settings){
		return null;
	}

	

	

	if(!UserData.loaded || !state.companies.companies){
        return (<div>Ladataan</div>);
    }
	const changeInputValue = (name,value,index) => {
	
       //setUserData({...UserData,data:{...UserData.data,[name]:value}})

		console.log(name,value,index);

        //dispatch({ type: "LOGINFORM_INPUT", key: name, value: value }, [dispatch]);
		//props.onChange(name,value,index);
	let jatkuvat=[...UserData.data.jatkuvat];
	jatkuvat[index]={...jatkuvat[index],[name]:value}
		setUserData({...UserData,data:{
			...UserData.data,
			
			jatkuvat:jatkuvat}})



	};
	
	const changeEditMode=(e)=>{
		if(!UserData.edit){
			setUserData({...UserData,"edit":true});
		}else{

			let d=JSON.parse(JSON.stringify(UserData.orginal));
			setUserData({...UserData,"edit":false,"data":{...d}});

		}
	
	};

	
	
	


const tryToSave=()=>{

	let url="persons/"+id+"/jatkuvat";
    
	let data={jatkuvat:[...UserData.data.jatkuvat]};
	
	apiCall.ADD(url, data,function(status,err_msgs=null,response){
	
		if(status==400){

			//setFolderData({...FolderData,errors:err_msgs})

			setUserData({...UserData,errors:err_msgs});
		}else if(status==200){
			
			setUserData({...UserData,"edit":false,errors:null,data:(response.data),orginal:JSON.parse(JSON.stringify((response.data)))});
			


			//apiCall.GET("users");
		}

	}).then(() => {
		//apiCall.GET('destinations');
		
	});




	
};
const handleJatkuvaAdding=(e)=>{

	var jatkuvat = [...UserData.data.jatkuvat,{...DefaultJatkuvaData,alkaa: state.common.current_period}];

	setUserData({...UserData,data:{...UserData.data,jatkuvat:jatkuvat}})


};
const editLoan=(name,value,index)=>{

	

		let loans=[...UserData.data.loans];
		loans[index]={...loans[index],[name]:value}
		setUserData({...UserData,data:{
			...UserData.data,
			
			loans:loans}})




	



};

const changeLainanLyhennys = (name,value) => {

	setUserData({...UserData,data:{
		...UserData.data,
		[name]:value
		
	}})
	//props.onChange(name,value);
};


const  changePeriod=(name,value,index)=>{

	//props.onChange(name,value,index);
	let jatkuvat=[...UserData.data.jatkuvat];
	jatkuvat[index]={...jatkuvat[index],[name]:value}
		setUserData({...UserData,data:{
			...UserData.data,
			
			jatkuvat:jatkuvat}})


};
const removeIndex=(index)=>{

	let jatkuvat=[...UserData.data.jatkuvat];
	
	 jatkuvat = jatkuvat.slice(0, index).concat(jatkuvat.slice(index + 1, jatkuvat.length))
	setUserData({...UserData,data:{...UserData.data,jatkuvat:jatkuvat}});

};
const items = UserData.data.jatkuvat.map((item,index) =>


 
        <tr key={index}>
            
            

        <td><Period onChange={changePeriod} name="alkaa"  index={index}   value={item.alkaa} edit={UserData.edit}/></td> 
		<td><Period onChange={changePeriod} allowreset={true} name="paattyy"  index={index}  value={item.paattyy} edit={UserData.edit}/></td> 

		{UserData.edit?<td><InputBoolean index={index} edit={true} data={item} onChange={editBoolean} label="Automaattinen?" name="automatic"/></td>:<td>{item.automatic==1?"Automaattinen":"Syötettävä"}</td>}

<td><InputText onChange={changeInputValue} data={item} index={index} name="selite" edit={UserData.edit}/></td>
        <td>{item.automatic==1?<InputDouble onChange={changeInputValue} data={item} index={index} name="summa" edit={UserData.edit}/>:"-"}</td>
       
		<td>{UserData.edit&&<span class='removeme' onClick={(e)=>removeIndex(index)}>Poista</span>}</td>

	    </tr>
        


	);
	const parseErrors=(arr)=>{


		return arr.map((item,index) =>


 
        <p key={index}>
            
            
		{item[index]}
        </p>
        


	);



	}

	const content=(<table className="table-new"><tbody><tr class="text-header"> <th>Alkaa</th><th>Päättyy</th><th>Tyyppi</th><th>Selite</th><th>Summa €</th><th></th></tr>{items}</tbody></table>);
	const getErrors=()=>{
		
		if(UserData.errors){
				


				let vals=[];
				console.log("ERRORIT OLI", UserData.errors);
			for(var i in UserData.errors){
				for(var x=0;x<UserData.errors[i].length;x++){
					vals.push(UserData.errors[i][x]);
				}
			}
			
			var errs = vals.map(function(o,i) {
				return <div className="error-set">{o}</div>
			});
			

			return <div className='error'>{errs}</div>;

		}

		return null;

	};

const errors=getErrors();
	return (

		<div className="mycontent person-content">
			
				<div className="inner">
				<div className="box">

				<h2>{UserData.data.firstname} {UserData.data.lastname}</h2>

				<div className={"edit-btn "+(UserData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Muokkaa</span></div>

					{UserData.edit?<div className="save-btn" onClick={tryToSave}><span>Tallenna</span></div>:null}


					<PersonTopNav  id={UserData.data.id}/>
					<div className="form-blocks">
				

					<div className="form-block settings">
						<h3>
						{UserData.edit?
						<div className="edit-btn" onClick={(e) => {
      				  handleJatkuvaAdding(e);
   				 }}><span>Lisää</span></div>:null}</h3>

				 	{errors}
					{content}
						
					</div>


					</div>
					
				</div>
					
			</div>
			
			
		</div>
	);
}
/*<SettingPair name="pohjapalkka" onChange={changeSettingValue} label="Pohjapalkka" defaultSettings={state.common.settings} settings={UserData.data.settings} edit={UserData.edit} />
						<SettingPair name="auto_leasing" onChange={changeSettingValue} label="Autoleasing" defaultSettings={state.common.settings} settings={UserData.data.settings} edit={UserData.edit} />
						<SettingPair name="ulosotto" onChange={changeSettingValue} label="Ulosotto" defaultSettings={state.common.settings} settings={UserData.data.settings} edit={UserData.edit} />
						<SettingPair name="puhelinetu" onChange={changeSettingValue} label="Puhelinetu" defaultSettings={state.common.settings} settings={UserData.data.settings} edit={UserData.edit} />
						<SettingPair name="lounassetelit" onChange={changeSettingValue} label="Lounassetelit" defaultSettings={state.common.settings} settings={UserData.data.settings} edit={UserData.edit} />
						

*/ 