import InputDouble from "./InputDouble.js";
import InputBoolean from "./InputBoolean.js";
import Period from "./Period.js";

export default function GlobalSettingTAble(props) {

   
    

    const changeValue=(name,value,index)=>{
       
        props.onChange(name,value,index);
    };

    const onRemove=(index)=>{
      
       props.onRemove(index);
    };
   
    const items = props.values.map((item,index) =>


 
       <tr key={index}>
          
          
          <td className="expires"><Period onChange={changeValue} name="effective_from" index={index}  value={item.effective_from} edit={props.edit}/></td>


   
          <td className="double"><InputDouble onChange={changeValue} data={item} index={index} name="value" edit={props.edit}/></td>
          {props.edit?<td><span onClick={e=>onRemove(index)}>Poista</span></td>:null}
          
        </tr>
      


    );

console.log("ediititit",props.edit);
    const head=(props.values.length?<thead><tr><th>Alkaen</th><th>Arvo</th><th></th></tr></thead>:null);


   
	return (<div className="settings-all"><table>{head}<tbody>{items}</tbody></table></div>);
}
