const defaultState = {
	loading:true,
	companies: null,
	firstLoad:false,
	mainLoader:true,
};

export const companiesInitialState = (() => {
	
	return defaultState;
})();

export const companiesReducer = (state = companiesInitialState, action) => {
	let companies = state;
	switch (action.type) {
		
		case "COMPANIES_LOADED":

			
			companies = {
				...state,
				mainLoader:false,
				loading: false,
				companies: action.payload.data,
			};
			break;

		case "COMPANIES_LOADING":
			companies = {
				...state,
				
				loading: true,
				firstLoad:true
				
			};
			break;

		case "COMPANIES_FAIL":
			
			companies = {
				...state,
				
				loading: true,
				companies: null
			};
			break;
		

		default:
			companies = state;
		break;
    }
    return companies;
};