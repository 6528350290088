import React,{useState,useEffect} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import InputDouble from "./form/InputDouble.js";

import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link,
    Nav,NavLink
	// etc.
  } from "react-router-dom";

export default function TopNav(props) {
	const { state, dispatch } = useStore();

  


	
	if(!state.users.items){
		return null;
	}



	/**/ 
	let nav=null;
	if(props.links){
		nav = props.links.map((item,index) =>
  		<li key={index}><NavLink  activeClassName="active"  to={item.url} exact>{item.name}</NavLink></li>
		);

	}
	
	return (
		<div className="topnav">
              
            {(nav?<ul>{nav}</ul>:null)}
              
               {/*<ul>
					
                    <li><NavLink activeClassName="active" to="/persons">Työntekijät</NavLink></li>
                    <li><NavLink activeClassName="active" to="/palkanlaskenta">Palkanlaskenta</NavLink></li>
                    <li><NavLink activeClassName="active" to="/maksaminen">Maksaminen</NavLink></li>
                    <li><NavLink activeClassName="active" to="/asetukset">Asetukset</NavLink></li>
               </ul>*/}
				
				<div className="search"><input type="text" /></div>
					
					
			
			
		</div>
	);
}
