import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputText from "./form/InputText.js";
import InputSelect from "./form/InputSelect.js";
import InputDouble from "./form/InputDouble.js";


import SettingPair from "./form/SettingPair.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";


import TopNav from "./TopNav.js";
import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";



 

export default function AdditionSubstraction(props) {


    

   
const onBlur=(name)=>{

props.onBlur();
}


/*<table>
							
							<thead>
								<tr><th>Tyyppi</th><th>Summa</th><th>Selite</th></tr>
							</thead>	
							<tbody>*/ 

                            const changeInputValue=(name,value,index)=>{

                                props.onChange(name,value,index);

                                if(name=="multiply"){
                                  props.onBlur();
                                }

                            };
                            const  removeIndex=(index)=>{

                              props.remove(index);

                            };

                            const types=[{id:1,name:"Provisioon Lisäys"},{id:-1,name:"Provisioon Vähennys"}];
                          

                            console.log("prows",props.palkkarows);
const palkkarows=props.palkkarows.map((item, index) => {

  return <tr className={(Math.sign(item.value)>0?"positive":(Math.sign(item.value)<0?"negative":null))} key={index}><td>{item.name}</td><td></td><td  className="amount">{item.value} €</td></tr>


});

    const rows=props.data.additionsandsubstractions.map((item, index) => {


            return <tr key={index} className={(item.multiply==1?"positive":"negative")}>
              <td><InputSelect  index={index} onChange={changeInputValue} options={types} data={item} name="multiply" edit={true}/>
            </td>
            <td><InputText onBlur={onBlur}  index={index} onChange={changeInputValue}  name="description" data={item} edit={true}/></td>
            <td className="amount">{(item.multiply==1?"":<span className="nega">-</span>)}<InputDouble onBlur={onBlur} index={index} onChange={changeInputValue}  name="value" data={item} edit={true}/></td>
            <td onClick={(e) => {
      				  removeIndex(index);
   				 }}>remove</td>
            </tr>

    });
    let sum=0.0;

    for(var i=0;i<props.palkkarows.length;i++){
      let item=props.palkkarows[i];
    
      sum+=item.value;


  }


    for(var i=0;i<props.data.additionsandsubstractions.length;i++){
        let item=props.data.additionsandsubstractions[i];
      
        sum+=item.multiply*item.value;


    }
    const total=<tr className="total-row"><td className="total-cell" colSpan="2">Yhteensä</td><td className="total-sum"> {sum} €</td><td></td></tr>;
	return (<table className="table add-sub"><thead>
        <tr><th>Tyyppi</th><th>Selite</th><th>Määrä</th><th><div className="edit-btn" onClick={(e) => {
      				  props.add(e);
   				 }}><span>Lisää</span></div></th></tr>
    </thead>	
    <tbody>{palkkarows}{rows}{total}</tbody></table>);
}
