import React,{useState,useEffect} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import {format, getTaxesForPeriod} from "../commonFunctions";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link,
    Nav,NavLink
	// etc.
  } from "react-router-dom";

export default function PreviewKaava(props) {
	const { state, dispatch } = useStore();

  
   // $data->nettopalkka= $data->brutto-$data->ennakonpidatys-$data->tyel-$data->tyottomyysvakuutusmaksu;

    let result_rows=[

    {label:"Brutto",formula:format(props.data.kaikki_yhteensa.value)+" € - "+format(props.data.kulukorvaukset_yhteensa.value)+" € =",value:props.data.brutto.value},
    {label:"Kulukorvaukset",formula:"",value:props.data.kulukorvaukset_yhteensa.value},

    {label:"Ennakonpidätyksen alainen tulo",formula:format(props.data.brutto.value)+" € + "+format(props.data.luontoisedut_yhteensa.value)+" € =",value:props.data.ennakonpidatyksenalainen.value},
    {label:"Ennakonpidätys ("+props.data.taxes.tax_rate+" %)",formula:"",value:props.data.ennakonpidatys_perusprosentti.value},
    {label:"Ennakonpidätys ("+props.data.taxes.addional_tax_rate+" %)",formula:"Tuloraja "+format(props.data.taxes.addional_tax_rate_limit)+" €",value:props.data.ennakonpidatys_lisaprosentti.value},
    {label:"Ennakonpidätys yhteensä",formula:"",value:props.data.ennakonpidatys.value},
    {label:"Tyel ("+props.data.tyel_procent.value+" %)",formula:props.data.tyel_procent.value+ "% * "+format(props.data.ennakonpidatyksenalainen.value)+" € =",value:props.data.tyel.value},
    {label:"Työttömyysvakuutusmaksu ("+props.data.tyottomyysvakuutusmaksu_procent.value+" %)",formula:props.data.tyottomyysvakuutusmaksu_procent.value+ "% * "+format(props.data.ennakonpidatyksenalainen.value)+" € =",value:props.data.tyottomyysvakuutusmaksu.value},
    
    {label:"Netto",formula:format(props.data.brutto.value)+" € -"+format(props.data.ennakonpidatys.value)+" € - "+format(props.data.tyel.value)+" € - "+format(props.data.tyottomyysvakuutusmaksu.value)+" € =",value:props.data.nettopalkka.value},
    {label:"Ulosotto",formula:"",value:props.data.ulosotto.value},
    {label:"Yrityslainojen lyhennykset",formula:"",value:props.data.yrityslainat_yhteensa.value},

    
    {label:"Netto vähennykset",formula:"",value:props.data.netto_vahennykset_total.value},
    {label:"Maksetaan",formula:format(props.data.nettopalkka.value)+"€ + "+format(props.data.kulukorvaukset_yhteensa.value)+" € + "+format(props.data.netto_vahennykset_total.value)+" € - "+format(props.data.ulosotto.value)+" € - "+format(props.data.yrityslainat_yhteensa.value)+" €=",value:props.data.maksetaan.value},

    ];
    /*    $data->maksetaan=$data->nettopalkka+$kulutyhteensa-$netto_vahennykset_total-$u_value;
*/ 
    
    /* $data->tyel=(object)array("type"=>"tyel","label"=>"Tyel","value"=>$data->tyel);
    $data->tyel_procent=(object)array("type"=>"tyel_procent","label"=>"Tyel%","value"=>$data->tyel_procent);
    $data->tyottomyysvakuutusmaksu=(object)array("type"=>"tyottomyysvakuutusmaksu","label"=>"Työttömyysvakuutusmaksu","value"=>$data->tyottomyysvakuutusmaksu);
    */ 
// $data->ennakonpidatyksenalainen=$data->brutto+$autoleasing_value+$puhelinetu_value+$lounasetu_total;
// $data->ennakonpidatys=($taxes?$this->getEnnakonPidatys($data->ennakonpidatyksenalainen,$taxes):0);
   



	
    const items= result_rows.map((item,index) =>{

        return (<tr key={index}><td>{item.label}</td><td className="formula">{item.formula}</td><td  className="amount">{format(item.value)} €</td></tr>:null);
      
      });

      
       


	return (
		<div className="preview">

			{props.topic?<h3>{props.topic}</h3>:null}
     
			<table className="table">
                <thead>

                    <tr><th></th><th class="formula"></th><th>Määrä</th></tr>
                </thead>
                <tbody>

                    {items}
                   
                   {/* <tr className="total-row negative"><td>Yhteensä</td><td className="amount">- {format(sum)} €</td></tr>
    */}
                </tbody>

            </table>
			
		</div>
	);
}
