export const formatValue=(item)=>{


   

            
        return item.value[item.type+"_value"];
    

};


export const number_format= (number, decimals, dec_point, thousands_sep)=>{
    // Strip all characters but numerical ones.
    number=parseFloat(number);

    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}
export const formatSeason=(d)=>{

   let dates= d.split("-");
  
   if(dates.length==3){

    return dates[1]+"/"+dates[0];
   }
   
   return d;

};
export const format=(v)=>{


v=Math.round((v+ Number.EPSILON) * 100) / 100;

return number_format(v,2,","," ");
};



export const getBonus=(bonuses,myynti)=>{

    let hit=null;

  
    for(var i=0;i<bonuses.length;i++){

        if(myynti>=bonuses[i].ilmoitettu_myynti_limit){


            hit=bonuses[i].bonus;

        }

    }
    if(hit){
        return hit;
    }
    return 0;


};



export const getPalkkakausi=(wages,period)=>{


    for(var i=0;i<wages.length;i++){

        if(wages[i].palkkakausi==period){

            return wages[i];
        }

    }
    return null;


};
export const getUserName=(values,id)=>{

   
    for(var i=0;i<values.length;i++){

        if(values[i].id==id){

            return values[i].lastname;
        }

    }
    return null;
};
export const getCommonSettingValue=(values,key_name)=>{


    for(var i=0;i<values.length;i++){


        

        if(key_name==values[i].key_name ){


           return values[i].value;
        }
    }
return false;

};

export const getCorrectPeriod=(items,period)=>{

    for(var i=0;i<items.length;i++){
        
        return items[0];

    }

    return null;

};
export const getActiveGlobalSetting=(values,PERIOD)=>{

    let t=[...values];
    if(t.length){
        t.sort((a, b) => Date.parse(a.effective_from) > Date.parse(b.effective_from) ? 1 : -1);

        let cur=null;
        let currentPeriod= Date.parse(PERIOD);
      
        for(var i=0;i<t.length;i++){

            var item_time= Date.parse(t[i].effective_from);
           
            if(currentPeriod>=item_time){
                cur=i;
            }

        }
        if(cur!==null){
            
               return t[cur].value;
           
        }

    }

    return false;

};
export const getActiveSetting=(sid,user_settings,PERIOD)=>{

    let f=[...user_settings];
    let t=[];
   
    for(var i=0;i<f.length;i++){

        if(f[i].usersettings_names_id==sid){
            t.push({...f[i]});
        }

    }
    if(t.length){
        t.sort((a, b) => Date.parse(a.effective_from) > Date.parse(b.effective_from) ? 1 : -1);
        let cur=null;
        let currentPeriod= Date.parse(PERIOD);
      
        for(var i=0;i<t.length;i++){

            var item_time= Date.parse(t[i].effective_from);
           
            if(currentPeriod>=item_time){
                cur=i;
            }

        }
        if(cur!==null){
            if(t[cur].active===1){
                
           
            return t[cur].value;
            }
        }
        return false;
    }
    return false;
  

};

/*  let t=[...values];
    if(t.length){
        t.sort((a, b) => Date.parse(a.effective_from) > Date.parse(b.effective_from) ? 1 : -1);

        let cur=null;
        let currentPeriod= Date.parse(PERIOD);
      
        for(var i=0;i<t.length;i++){

            var item_time= Date.parse(t[i].effective_from);
           
            if(currentPeriod>=item_time){
                cur=i;
            }

        }
        if(cur!==null){
            
               return t[cur].value;
           
        }

    }

    return false;*/

    export const getEnnakonPidatys=(ennakonpidatyksenalainen,tax)=>{
        console.log(tax);
        if(tax){
            return tax.tax_rate/100*ennakonpidatyksenalainen;

        }

        return 0;


    }
export const getTaxesForPeriod=(taxes,PERIOD)=>{
    let t=[...taxes];
    if(t.length){

        
        t.sort((a, b) => Date.parse(a.effective_from) > Date.parse(b.effective_from) ? 1 : -1);
        let cur=null;
        let currentPeriod= Date.parse(PERIOD);
        
        for(var i=0;i<t.length;i++){

            var item_time= Date.parse(t[i].effective_from);
           
            if(currentPeriod>=item_time){
                cur=i;
            }

        }
        if(cur!==null){
            
               return t[cur];
           
        }

    }
   return false;
}
export const getGlobalSettingsForPeriod=(settigns,PERIOD)=>{

    let o=new Object();

    for(var i=0;i<settigns.length;i++){

        o[settigns[i].key_name]=getActiveGlobalSetting(settigns[i].values,PERIOD);

       


    }

    return o;




};
export const getUserSettingsForPeriod=(settings_names,user_settings,PERIOD)=>{

    let o=new Object();

    for(var i=0;i<settings_names.length;i++){

        o[settings_names[i].key_name]=getActiveSetting(settings_names[i].id,user_settings,PERIOD);

       


    }

    return o;

};
export const isSettingActive=(settings,key_name,PERIOD=null)=>{
    console.log("isSettingActive",settings,key_name,PERIOD);

    for(var i=0;i<settings.length;i++){

        

        if(key_name===settings[i].key_name ){
            
          
           const period=getCorrectPeriod(settings[i].values,PERIOD);
           console.log("hititiititi",period);
            if(period){

                if(period.active==1){

                    return true;
                }
            }

            return false;
        }
    }
return false;

};
export const getCommonSetting=(default_values,key_name)=>{

    for(var i=0;i<default_values.length;i++){


        

        if(key_name==default_values[i].key_name ){


           return default_values[i];
        }
    }
return false;



};
export const getSettingIndex=(values,key_name)=>{


    for(var i=0;i<values.length;i++){

        if(key_name==values[i].key_name ){


           return i;
        }
    }

    return false;




};


export const getSettingType=(default_values,key_name)=>{


    for(var i=0;i<default_values.length;i++){




        if(key_name==default_values[i].key_name ){


           return default_values[i].type;
        }
    }




};



export const getSettingValue=(settings,key_name,PERIOD=null)=>{


    console.log("seracch getSettingValue",settings,key_name);

    for(var i=0;i<settings.length;i++){

        

        if(key_name===settings[i].key_name ){
            
          
           const period=getCorrectPeriod(settings[i].values,PERIOD);
           console.log("hititiititi",period);
            if(period){

                

                    return period.value;
               
            }

            return null;
        }
    }
return null;




};






export const commonFunctions = (state, dispatch) => {


    const companies=state.companies.companies;
console.log(companies);
    return {    
		

		getFolder: (id) => {

            const searchFolder=(id,item)=>{
               

                console.log("search",item.name);


                if(item.id==id){
                    console.log("HIT",item); 
                    return item;
                }else{
                   
                    for(var i=0;i<item.children.length;i++){
                        
                        let found=searchFolder(id,item.children[i]);


                        if(found!==false){
                            return found;
                        }

                    }

                }

                return false;

            };

          
           for(var i=0;i<companies.length;i++){

            console.log("tästä",companies[i])
                    for(var x=0;x<companies[i].folders.length;x++){

                        let found=searchFolder(id,companies[i].folders[x]);
                    if(found!==false){
                        return found;
                    }


                    }
                    
           }



           return false;


        }

    }
};