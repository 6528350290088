import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputText from "./form/InputText.js";
import Alaiset from "./Alaiset.js";
import InputSelect from "./form/InputSelect.js";

import SettingPair from "./form/SettingPair.js";
import Settings from "./form/Settings.js";
import EsimiesAndRole from "./form/EsimiesAndRole.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";
import Loans from "./form/Loans.js";
import BonusTable from "./BonusTable.js"
import PersonTopNav from "./PersonTopNav.js";
import { apiClient } from "../apiCall";

import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";



  export const defaultUserData={

	loaded:false,
	edit:false,
	data:null,
	orginal:null
	
};

export const DefaultTaxData={
	effective_from:"2021-01-01",
	tax_rate:0,
	addional_tax_rate:0,
	addional_tax_rate_limit:0

};
export const DefaultLoanData={
	palkkakausi:"2021-01-01",
	
	laina:0,
	takaisinmaksu:0,
	lyhennys:0


};
export default function PersonTaxes() {
	const { state, dispatch } = useStore();
    let { id } = useParams();
  
    const [UserData, setUserData] = useState({
		...defaultUserData
		
    });

	const apiCall = apiClient(state, dispatch);

	const sortData=(data)=>{


		
		data.settings.sort((a, b) => Date.parse(a.effective_from) < Date.parse(b.effective_from) ? 1 : -1)


		return data;

	};

    useEffect(() => {
		fetch( config.apiUrl+"/persons/"+id,{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })}).then((res) => res.json())
		  .then(data => setUserData({data:sortData(data),orginal:JSON.parse(JSON.stringify(sortData(data))),loaded:true}))

	  }, []);






	
	if(!state.users.items || !state.common.settings){
		return null;
	}


	

	if(!UserData.loaded || !state.companies.companies){
        return (<div>Ladataan</div>);
    }
	

	
	const changeTaxes=(name,value,index)=>{

		let taxes=[...UserData.data.taxes];
		taxes[index]={...taxes[index],[name]:value}
		setUserData({...UserData,data:{
			...UserData.data,
			
			taxes:taxes}})

    };
	const handleTaxAdding=(e)=>{
		
		var taxes = [...UserData.data.taxes,{...DefaultTaxData,effective_from: state.common.current_period}];
		setUserData({...UserData,data:{...UserData.data,taxes:taxes}})
		
	};
	const changeEditMode=(e)=>{
		if(!UserData.edit){
			setUserData({...UserData,"edit":true});
		}else{

			let d=JSON.parse(JSON.stringify(UserData.orginal));
			setUserData({...UserData,"edit":false,"data":{...d}});

		}
	
	};

	
	
	


const tryToSave=()=>{

	let url="persons/"+id+"/taxes";
	let data={taxes:[...UserData.data.taxes]};
	
	apiCall.ADD(url, data,function(status,err_msgs=null){
	
		if(status==400){

			//setFolderData({...FolderData,errors:err_msgs})
		}else if(status==200){
			setUserData({...UserData,"edit":false});
			apiCall.GET("users");
		}

	}).then(() => {
		//apiCall.GET('destinations');
		
	});




	
};





	return (

		<div className={"mycontent person-content"+((UserData.data.department?" "+UserData.data.department.style:null))}>

		
				<div className="inner">
				<div className="box">

				<h2>{UserData.data.firstname} {UserData.data.lastname}</h2>

				<div className={"edit-btn "+(UserData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Muokkaa</span></div>

					{UserData.edit?<div className="save-btn" onClick={tryToSave}><span>Tallenna</span></div>:null}


					<PersonTopNav  id={UserData.data.id}/>

					<div className="form-blocks">
					
					
					<div className="form-block">

						<h3>Verotiedot
						{UserData.edit?
						<div className="edit-btn" onClick={(e) => {
      				  handleTaxAdding(e);
   				 }}><span>+</span></div>:null}</h3>

						<TaxRates  onChange={changeTaxes} taxes={UserData.data.taxes}  edit={UserData.edit}/>

						

					</div>
					
					


					</div>
					
				</div>
					
			</div>
			
			
		</div>
	);
}
