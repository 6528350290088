import React,{useState,useEffect} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import {format} from "../commonFunctions";
import InputDouble from "./form/InputDouble.js";

import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link,
    Nav,NavLink
	// etc.
  } from "react-router-dom";
import InputText from "./form/InputText.js";
/*autoetu: {label: "Autoetu", value: 200, type: "luontoisetu"}
bonus: {label: "Oma Bonus", value: 950, type: "palkka"}
bonus_alaiset: {label: "Bonus omista  alaisista", value: 1500, type: "palkka"}
bonus_ryhma: {label: "Bonus alaisista (ryhmä)", value: 2000, type: "palkka"}
brutto: 7354.900000000001
duell_laskup: {label: "Duell lasku p", value: -125, type: "palkka"}
ennakkoprovisio: {label: "Ennakkoprovisio", value: -0, type: "palkka"}
ennakonpidatyksenalainen: 7714.500000000001
ennakonpidatys: 2661.5025
ilmoitettu_myynti: {label: "Ilmoitettu myynti", value: 19955, type: "variable"}
kaikki_yhteensa: 8652.1
kilometrit: {label: "Kilometrikorvaus", description: "4992 km x 0.1 €/km", value: 499.20000000000005, type: "kulukorvaus"}
koulutuskilometrikorvaus: {label: "Koulutus km korvaus", description: "74 km x á 0.15 €)", value: 11.1, type: "palkka"}
koulutuskorvaus: {label: "Korvaus koulutuspäivistä", description: "3 kpl x á 280 €)", value: 840, type: "palkka"}
kulukorvaukset_yhteensa: 1297.2
laskutettu_myynti: {label: "Laskutettu myynti", value: 19955, type: "variable"}
lounasetu: {label: "Lounassetelit", description: "20 kpl*6.98 €", value: 139.60000000000002, type: "luontoisetu"}
maksetaan: 4854.151250000001
netto_vahennykset: (3) [{…}, {…}, {…}]
nettopalkka: 4056.951250000001
paivarahat: {label: "Päivärahat", description: "19kpl x 42 €", value: 798, type: "kulukorvaus"}
pohjapalkka: {label: "Pohjapalkka ilman rajaa", value: 1000, type: "palkka"}
provisio: {label: "Provisio", value: 2476, type: "palkka"}
puhelinetu: {label: "Puhelinetu", value: 20, type: "luontoisetu"}
tyel: 489.87075000000004
tyottomyysvakuutusmaksu: 146.5755*/ 

const defaultItemValues={

  items:[],
  itemtexts:[],
  edit:false

 };


export default function PreviewNettoVahennykset(props) {
	const { state, dispatch } = useStore();

    const [open,setOpen]=useState(false);
var defValues=[];
var defValuesText=[];
    if(props.data.length){
        for(var i=0;i<props.data.length;i++){
          defValues.push(Math.abs(props.data[i].value));

          defValuesText.push(props.data[i].label);
        }
    }

    const [ItemValues,setItemValues]= useState({
      ...defaultItemValues,items:[...defValues],itemtexts:[...defValuesText]
      
      });


    const toggleOpen=()=>{
  
      
      setOpen(!open);

    };
 
   const toggleEdit=()=>{
   
    setItemValues({...ItemValues,edit:!ItemValues.edit})

   }
   const changeInputTextValue=(name,value,index)=>{
    let vals=[...ItemValues.itemtexts];
    vals[index]=value;
    setItemValues({...ItemValues,itemtexts:[...vals]});

   };
const changeInputValue=(name,value,index)=>{

  //setItemValues();
  let vals=[...ItemValues.items];
  vals[index]=value;
  setItemValues({...ItemValues,items:[...vals]});
}
const saveIndex=(item,index)=>{

  props.onsave(item.id,ItemValues.items[index],ItemValues.itemtexts[index]);

}
const showSave=(index)=>{


  if(Math.abs(ItemValues.items[index])===Math.abs(props.data[index].value) && ItemValues.itemtexts[index]===props.data[index].label){
    return false;
  }
  
return true;

}
	const remove=(item)=>{
    console.log(item);
    props.onremove(item.id);

  }
    const items= props.data.map((item,index) =>{

        return (<tr key={index} className={item.is_positive===0?"negative":null}>

          {ItemValues.edit?
          <InputText onChange={changeInputTextValue}  index={index} name="value" data={{value:ItemValues.itemtexts[index]}} edit={true}/>
          :
          <td>{item.label}</td>}
          <td  className="amount">{item.is_positive===0?"-":null} {ItemValues.edit?<div className="medit"><InputDouble onChange={changeInputValue}  index={index} name="value" data={{value:ItemValues.items[index]}} edit={true}/></div>
        :format(item.value)} {!ItemValues.edit && "€"}</td>
        <td className="td-btn">{showSave(index)&&<span className="store-btn" onClick={(e)=>saveIndex(item,index)}>Tallenna</span>}{ItemValues.edit&&<span className="remove-me" onClick={(e)=>{remove(item)}}>Poista</span>}</td></tr>:null);
      
      });

      let sum=0.0;
        for(var i in props.data){

            

                        sum+=props.data[i].value;
            

        }
       
const closeEditor=()=>{
var defValues=[];
var defValuesText=[];
  if(props.data.length){
    for(var i=0;i<props.data.length;i++){
      defValues.push(Math.abs(props.data[i].value));

      defValuesText.push(props.data[i].label);
    }
}

setItemValues({...ItemValues,items:[...defValues],itemtexts:[...defValuesText],edit:false})



 // toggleEdit();
};

	return (
		<div className=" preview">

			{props.topic?<h4 className={(open?"arrow open":"arrow closed")}  onClick={toggleOpen}>{props.topic}  <span className="total">{format(sum)} €</span></h4>:null}
      {props.allowedit && open&&<div className="btn-holder"><div className='btn' onClick={toggleEdit}>Avaa muokkaus</div></div>}
          
          {ItemValues.edit && <div className="fixed-hide-non"></div>}
            {open?
          
			<div id="fixed-holder" className={ItemValues.edit?"showfixed":null}><table className="table">
                <thead>

                    <tr><th>Selite</th><th>Määrä</th></tr>
                </thead>
                <tbody>

                    {items}
                   
                    {!ItemValues.edit? <tr className="total-row "><td>Yhteensä</td><td className="amount"> {format(sum)} €</td></tr>:<tr><td colSpan="3"><button onClick={closeEditor}>Sulje</button></td></tr>}

                </tbody>

            </table></div>
            :null}
			
		</div>
	);
}
