import React,{useState,useEffect} from "react";
import { useStore } from "../../store/useStore";
import { searchSetting } from "../../commonFunctions";
import axios from "axios";
import { config } from "../../config";
import InputText from "./InputText.js";
import InputSelect from "./InputSelect.js";
import InputDouble from "./InputDouble.js";
import { apiClient } from "../../apiCall";












import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";



  export const defaultKayttjatData={

	

    name:"",
    adminroles_id:null,
    email:""
   
  
	
	
};
export const DefaultRequestErrors={

	errors:null
}

export default function AdminUser(props) {

    const { state, dispatch } = useStore();

    const apiCall = apiClient(state, dispatch);

    const [KayttajatData, setKayttajatData] = useState({
		...defaultKayttjatData
		
    });
    const [RequestErrors,setRequestErrors] = useState({
      ...DefaultRequestErrors
        
      });
      const onFocus=(name,value,index)=>{
        let key=name;
      
        let errors={...RequestErrors.errors};
        errors[key]=null;
        setRequestErrors({errors:{...errors}});
      };
    const save=()=>{

        if(checkIsOk()){


            if(props.id===undefined || props.id===null){

                let url="addadminuser";


  apiCall.ADD(url, {...KayttajatData},function(status,err_msgs,response){
    if(status==400){
      console.log(err_msgs);
      setRequestErrors({errors:{...err_msgs}});
      //setFolderData({...FolderData,errors:err_msgs})
    }else if(status==200){
     
      setKayttajatData({...defaultKayttjatData});
      if(props.onStore){

          props.onStore();
      }
   /*
      let org=[...MemoData.orginal];
      org.unshift({...response.data});
      console.log("FROOM ",data)
    
      let editIndexes=[...MemoData.editindexes];
      for(var i=0;i<editIndexes.length;i++){
        editIndexes[i]++;
      }

      setMemoData({...MemoData,data:data,editindexes:[...editIndexes],orginal:org})*/


    }
  });

            }

        }

    };
    const updateField=(name,value)=>{

        setKayttajatData({...KayttajatData,[name]:value});
    };
    const changeRole=(name,value)=>{
        setKayttajatData({...KayttajatData,[name]:parseInt(value)});

    };
    const checkIsOk=()=>{


        if(KayttajatData.name.trim()!="" && KayttajatData.email.trim()!="" && parseInt(KayttajatData.adminrole_id)>0){
            
            return true;

        }
        return false;

    };
    const isOk=checkIsOk();
    const types=state.common.adminroles;
console.log("EeRORS",RequestErrors.errors);
    return <div className="form-blocks">
        <div className="form-block">
         <div className="left"><InputText  onFocus={onFocus} useSimpleKey={true} onChange={updateField} name="name" label={"Nimi"} data={KayttajatData} errors={RequestErrors.errors} edit={true}/></div>
        <div className="right"><InputText  onFocus={onFocus} useSimpleKey={true}  onChange={updateField} name="email" label={"Email"} data={KayttajatData} errors={RequestErrors.errors} edit={true}/></div>

        
        </div>
        <div className="form-block">

        <div className="left"><InputSelect  onFocus={onFocus}  useSimpleKey={true} onChange={changeRole} zero={"--Valitse rooli--"} options={types} errors={RequestErrors.errors} data={KayttajatData} name="adminrole_id" edit={true}/>
        </div>

    <div className="right"><div onClick={save} className={"save-btn"+(!isOk?" disabled":"")}><span>Tallenna</span></div></div>

        </div>
        </div>
    
}
