import InputProcent from "./InputProcent.js";
import InputDouble from "./InputDouble.js";
import Period from "./Period.js";


export default function Remove(props) {

  
    const click = () => {


        props.onRemove(props.name,props.index);
    };
    




    

	return (props.edit?<button className="remove" onClick={click}>Poista</button>:null);
}
