import React,{useState,useEffect} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import {format} from "../commonFunctions";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link,
    Nav,NavLink
	// etc.
  } from "react-router-dom";

export default function PreviewTable(props) {
	const { state, dispatch } = useStore();

  
    const [open,setOpen]=useState(false);

    const toggleOpen=()=>{
  
      
      setOpen(!open);
    };
  
    

	
    const items= Object.keys(props.data).map((key,index) =>{

        return (props.data[key].type===props.key_name?<tr key={index} className={(Math.sign(props.data[key].value)>0?"positive":(Math.sign(props.data[key].value)<0?"negative":"gray"))} ><td>{props.data[key].label}</td><td>{props.data[key].description}</td><td  className="amount">{format(props.data[key].value)} €</td></tr>:null);
      
      });

      let sum=0.0;
        for(var i in props.data){

            if(props.data[i].type===props.key_name){

                        sum+=props.data[i].value;
            } 

        }
        


	return (
		<div className=" preview">

			{props.topic?<h4 className={(open?"arrow open":"arrow closed")}  onClick={toggleOpen}>{props.topic} <span className="total">{format(sum)} €</span></h4>:null}
            {open?
			<table className="table">
                <thead>

                    <tr><th>Tyyppi</th><th>Selite</th><th>Määrä</th></tr>
                </thead>
                <tbody>

                    {items}
                   
                    <tr className="total-row"><td colSpan="2">Yhteensä</td><td>{format(sum)} €</td></tr>

                </tbody>

            </table>
			:null}
		</div>
	);
}
