import InputProcent from "./InputProcent.js";
import InputDouble from "./InputDouble.js";
import Period from "./Period.js";
import LoanLyhennykset from "./LoanLyhennykset.js";

export default function Loans(props) {

    const changeProcentValue = (name,value,index) => {

 
        props.onChange(name,value,index);
    };

    const changeDoubleValue = (name,value,index) => {


        props.onChange(name,value,index);
    };
    const  changePeriod=(name,value,index)=>{

        props.onChange(name,value,index);
    };

    const changeLainanLyhennys = (name,value) => {


        //props.onChange(name,value);
props.changeLainanLyhennys(name,value);
    };
    


    const header=<tr><th>Lyhennykset alkaa</th><th>Lainan määrä</th><th>Lainan kokonaismäärä</th></tr>;

    const items = props.loans.map((item,index) =>


 
  <tr key={index}>
      
      <td><Period onChange={changePeriod} name="palkkakausi" index={index}  value={item.palkkakausi} edit={props.edit}/></td>

  <td><InputDouble onChange={changeDoubleValue} data={item} index={index} name="laina" edit={props.edit}/></td>
  <td><InputDouble onChange={changeDoubleValue} data={item} index={index} name="takaisinmaksu" edit={props.edit}/></td>


  </tr>
   


	);


	return (<div>
        <table className="lainan-lyhennykset"><thead>{header}</thead><tbody>
            
            {items}
            <tr><td colSpan="2"></td><td>{props.payments.total_loans}</td></tr>
            </tbody></table>


        <div className="loan-status">Lainoja jäljellä {props.payments.get_balance} €</div>
        <div><InputDouble onChange={changeLainanLyhennys} data={props.data}  label="Lainan lyhennykset yhteensä kuukaudessa" name="lyhennys" edit={props.edit}/></div>

       
        <h3>Lainojen lyhennykset</h3>
        <LoanLyhennykset data={props.payments} />
        
        
        </div>);
}
