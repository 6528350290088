


export default function InputBoolean(props) {

    const changeInputValue = (e) => {
		let name = e.target.name;
		let value = (props.data[props.name]===1?0:1);
       
        props.onChange(name,(value),props.index);
     
	};
   
    const input=<input name={props.name} type="checkbox" value={props.data[props.name]} checked={(props.data[props.name]==1?true:false)} 
    onChange={(e) => {
        changeInputValue(e);
    }} />;
    /*const d={props.edit==true?:{props.data[props.name]}};
   
    */
    const  text =(props.data[props.name]===1?"Kyllä":"Ei");

   const content=(props.edit==true?input:text);
  
 



   return (<div className="checbox"><label>{(props.label!==null && props.label!==undefined?props.label+" ":null)}{content}</label></div>);
}
