import InputProcent from "./InputProcent.js";
import InputDouble from "./InputDouble.js";
import Period from "./Period.js";


export default function TaxRates(props) {

    const changeProcentValue = (name,value,index) => {


        props.onChange(name,value,index);
    };

    const changeDoubleValue = (name,value,index) => {


        props.onChange(name,value,index);
    };
    const  changePeriod=(name,value,index)=>{

        props.onChange(name,value,index);
    };
    const header=<tr><th>Voimaantulo</th><th>Perusprosentti</th><th>Lisäprosentti</th><th>Tuloraja</th></tr>;

    const items = props.taxes.map((item,index) =>


 
  <tr key={index}><td><Period onChange={changePeriod} name="effective_from" index={index}  value={item.effective_from} edit={props.edit}/></td>
  <td><InputProcent onChange={changeProcentValue} data={item} index={index} name="tax_rate" edit={props.edit}/></td>
  <td><InputProcent onChange={changeProcentValue} data={item} index={index} name="addional_tax_rate" edit={props.edit}/></td>
  <td><InputDouble onChange={changeDoubleValue} data={item} index={index} name="addional_tax_rate_limit" edit={props.edit}/></td>

  </tr>
   


	);


	return (<table className="taxrates"><thead>{header}</thead><tbody>{items}</tbody></table>);
}
