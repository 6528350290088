import React,{useState,useEffect} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import {format} from "../commonFunctions";
import InputDouble from "./form/InputDouble.js";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link,
    Nav,NavLink
	// etc.
  } from "react-router-dom";
/*autoetu: {label: "Autoetu", value: 200, type: "luontoisetu"}
bonus: {label: "Oma Bonus", value: 950, type: "palkka"}
bonus_alaiset: {label: "Bonus omista  alaisista", value: 1500, type: "palkka"}
bonus_ryhma: {label: "Bonus alaisista (ryhmä)", value: 2000, type: "palkka"}
brutto: 7354.900000000001
duell_laskup: {label: "Duell lasku p", value: -125, type: "palkka"}
ennakkoprovisio: {label: "Ennakkoprovisio", value: -0, type: "palkka"}
ennakonpidatyksenalainen: 7714.500000000001
ennakonpidatys: 2661.5025
ilmoitettu_myynti: {label: "Ilmoitettu myynti", value: 19955, type: "variable"}
kaikki_yhteensa: 8652.1
kilometrit: {label: "Kilometrikorvaus", description: "4992 km x 0.1 €/km", value: 499.20000000000005, type: "kulukorvaus"}
koulutuskilometrikorvaus: {label: "Koulutus km korvaus", description: "74 km x á 0.15 €)", value: 11.1, type: "palkka"}
koulutuskorvaus: {label: "Korvaus koulutuspäivistä", description: "3 kpl x á 280 €)", value: 840, type: "palkka"}
kulukorvaukset_yhteensa: 1297.2
laskutettu_myynti: {label: "Laskutettu myynti", value: 19955, type: "variable"}
lounasetu: {label: "Lounassetelit", description: "20 kpl*6.98 €", value: 139.60000000000002, type: "luontoisetu"}
maksetaan: 4854.151250000001
netto_vahennykset: (3) [{…}, {…}, {…}]
nettopalkka: 4056.951250000001
paivarahat: {label: "Päivärahat", description: "19kpl x 42 €", value: 798, type: "kulukorvaus"}
pohjapalkka: {label: "Pohjapalkka ilman rajaa", value: 1000, type: "palkka"}
provisio: {label: "Provisio", value: 2476, type: "palkka"}
puhelinetu: {label: "Puhelinetu", value: 20, type: "luontoisetu"}
tyel: 489.87075000000004
tyottomyysvakuutusmaksu: 146.5755*/ 
export default function YritysLainat(props) {
	const { state, dispatch } = useStore();
    const [maxError, setMaxError] = useState(
		false
		
    );
    const changeInputValue=( name,value)=>{
      console.log(name,value);


      


      
      
        props.setYritysLainaLyhennys(value);

    };
    const onBlur=(e)=>{

        props.onBlur(null,null);
    };
    useEffect(() => {
		console.log("VALUE CHANGE",props.value);
        let d={yrityslainan_lyhennys:((props.value===null || props.value===undefined) ?props.default:props.value)};
            if(props.value>props.data.maksimi_lyhennys){
                if(!maxError){
                    setMaxError(true);
                }
            }else{
            if(maxError){
                setMaxError(false);
         }
  
        }

        
        
	  }, [props.value]);

        const lainat=props.data.lyhennettavat.map((item,index) =>{

            return <tr key={index}><td>{item.palkkakausi}</td><td>{item.laina}</td><td>{item.takaisinmaksu}</td><td>{item.maksettu}</td><td>{item.jaljella}</td>
                
                </tr>;
          
          });


          const countTotal = (key) => {
          
          
                return props.data.lyhennettavat.reduce(function(prev, current) {
                    return prev + +current[key]
                  }, 0);
            
        };

       

         console.log("DEFAULT",props.default);
         console.log("value",props.value);
         let errors=null;
         if(maxError){
             let k="data.yrityslainan_lyhennys";
             errors=new Object();
            errors[k]="Lyhennyksen määrä ylittää "+props.data.maksimi_lyhennys+" €";
         }
         console.log("ERRORS",errors);
        let d={yrityslainan_lyhennys:((props.value===null || props.value===undefined) ?props.default:props.value)}


        if(props.data.maksimi_lyhennys===0 && d!=0){
            return null;
        }
	return (
		<div className=" preview yrityslainat">

		
     
			<table className="table">
                <thead>

                    <tr><th>Lyhennykset alkaa</th><th>Lainan määrä</th><th>Takaisinmaksun määrä</th><th>Maksettu</th><th>Jäljellä</th></tr>
                </thead>
                <tbody>

                  
                    {lainat}
                    <tr className="total"><td>Yhteensä</td><td>{ countTotal("laina")}</td><td>{ countTotal("takaisinmaksu")}</td><td>{ countTotal("maksettu")}</td><td>{ countTotal("jaljella")}</td></tr>
                  
                    <tr><td colspan="3"><InputDouble   onBlur={onBlur} onChange={changeInputValue} label="Lyhennys" errors={errors} name="yrityslainan_lyhennys" data={d} edit={true}/></td></tr>
                </tbody>

            </table>
			
		</div>
	);
}
