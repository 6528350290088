import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputText from "./form/InputText.js";
import InputBoolean from "./form/InputBoolean.js";
import DateSelect from "./form/DateSelect.js";

import Alaiset from "./Alaiset.js";
import InputSelect from "./form/InputSelect.js";

import SettingPair from "./form/SettingPair.js";
import Settings from "./form/Settings.js";
import EsimiesAndRole from "./form/EsimiesAndRole.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";
import Loans from "./form/Loans.js";
import BonusTable from "./BonusTable.js"
import PersonTopNav from "./PersonTopNav.js";
import { apiClient } from "../apiCall";
import CustomDate from "./form/CustomDate.js";

import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";


  
  export const defaultUserData={

	loaded:false,
	edit:false,
	data:null,
	orginal:null
	
};

export const DefaultRequestErrors={

	errors:null
}
export default function Person() {
	const { state, dispatch } = useStore();
    let { id } = useParams();
  
    const [UserData, setUserData] = useState({
		...defaultUserData
		
    });

	const [RequestErrors,setRequestErrors] = useState({
	...DefaultRequestErrors
		
    });
	

	const apiCall = apiClient(state, dispatch);

	const sortData=(data)=>{


		
		data.settings.sort((a, b) => Date.parse(a.effective_from) < Date.parse(b.effective_from) ? 1 : -1)


		return data;

	};

    useEffect(() => {
		console.log("FETCH",id);
		fetch( config.apiUrl+"/persons/"+id,{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data => setUserData({data:sortData(data),orginal:JSON.parse(JSON.stringify(sortData(data))),loaded:true}))

	  }, [id]);






	
	if(!state.users.items || !state.common.settings){
		return null;
	}

	if(!UserData.loaded || !state.companies.companies){
        return (<div>Ladataan</div>);
    }
	const changeInputValue = (name,value) => {
	
       setUserData({...UserData,data:{...UserData.data,[name]:value}})



     
	};

	const changeEditMode=(e)=>{
		if(!UserData.edit){
			setUserData({...UserData,"edit":true});
		}else{

			let d=JSON.parse(JSON.stringify(UserData.orginal));
			setUserData({...UserData,"edit":false,"data":{...d}});

		}
	
	};

	
	


const tryToSave=()=>{

	let url="persons/"+id;
	let data={...UserData};
	
	apiCall.ADD(url, data,function(status,err_msgs=null){
	
		if(status==400){
			console.log(err_msgs);
			console.log(RequestErrors);
			//setErrors({errors:{...err_msgs}})
			setRequestErrors({errors:{...err_msgs}});
			//setFolderData({...FolderData,errors:err_msgs})
		}else if(status==200){
			setUserData({...UserData,"edit":false});
			setRequestErrors({errors:null});
			apiCall.GET("users");
		}

	}).then(() => {
		//apiCall.GET('destinations');
		
	});




	
};
const onFocus=(name,value,index)=>{
	let key="data."+name;
	console.log(RequestErrors);

	let errors={...RequestErrors.errors};
	errors[key]=null;
	setRequestErrors({errors:{...errors}});
};

console.log("ERRORS",RequestErrors);
console.log("ERRORS",UserData.data);

	return (

		<div className={"mycontent person-content"+((UserData.data.department?" "+UserData.data.department.style:null))}>
			
				<div className="inner">
			
				<div className="box">

				<h2>{UserData.data.firstname} {UserData.data.lastname}</h2>

				<div className={"edit-btn "+(UserData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Muokkaa</span></div>

					{UserData.edit?<div className="save-btn" onClick={tryToSave}><span>Tallenna</span></div>:null}


					<PersonTopNav  id={UserData.data.id}/>

					<div className="form-blocks">
					<div className="form-block">

						
						
						<div className="left">
							<InputText onFocus={onFocus} errors={RequestErrors.errors} label="Etunimi" onChange={changeInputValue} data={UserData.data} name="firstname" edit={UserData.edit}/>
							<InputText onFocus={onFocus}  errors={RequestErrors.errors} label="Sukunimi" onChange={changeInputValue} data={UserData.data} name="lastname" edit={UserData.edit}/>
							
							{/*<CustomDate  errors={RequestErrors.errors} label="Syntymäaika" name="birth_date" onChange={changeInputValue} data={UserData.data} onFocus={onFocus} errors={RequestErrors.errors} edit={UserData.edit}/>*/}
							<DateSelect errors={RequestErrors.errors}  name="birth_date" label="Syntymäaika" onChange={changeInputValue} data={UserData.data} edit={UserData.edit}/>

							<InputText onFocus={onFocus}  errors={RequestErrors.errors} label="Email" onChange={changeInputValue} data={UserData.data} name="email" edit={UserData.edit}/>
							
							<InputSelect onFocus={onFocus}  errors={RequestErrors.errors} label="Yritys" onChange={changeInputValue} options={state.companies.companies} data={UserData.data} name="department_id" edit={UserData.edit}/>
							
							
							

							<CustomDate label="Työsuhde alkaa" name="start_date" onChange={changeInputValue} data={UserData.data} onFocus={onFocus} errors={RequestErrors.errors} edit={UserData.edit}/>
							<CustomDate  label="Työsuhde päättyy" name="end_date" onChange={changeInputValue} data={UserData.data} onFocus={onFocus} errors={RequestErrors.errors} edit={UserData.edit}/>

						
						</div>
						



						<div className="right">

						
						<InputText onFocus={onFocus}  errors={RequestErrors.errors} label="Katuosoite" onChange={changeInputValue} data={UserData.data} name="streetaddress" edit={UserData.edit}/>
						<InputText onFocus={onFocus}  errors={RequestErrors.errors} label="Postinumero" onChange={changeInputValue} data={UserData.data} name="postalcode" edit={UserData.edit}/>
						<InputText onFocus={onFocus}  errors={RequestErrors.errors} label="Kaupunki" onChange={changeInputValue} data={UserData.data} name="city" edit={UserData.edit}/>
						<InputText onFocus={onFocus}  errors={RequestErrors.errors} label="Myyjätunnus" onChange={changeInputValue} data={UserData.data} name="myyjatunnus" edit={UserData.edit}/>
<InputBoolean  onFocus={onFocus}  errors={RequestErrors.errors} label="Onko pankki käytössä?" onChange={changeInputValue} data={UserData.data} name="haspankki" edit={UserData.edit}/>
						</div>

					</div>
					<div className="form-block">

						<h3>Pankkitiedot</h3>
						
						<InputText onFocus={onFocus}  errors={RequestErrors.errors} label="Pankki" onChange={changeInputValue} data={UserData.data} name="bank_name" edit={UserData.edit}/>
						<InputText onFocus={onFocus}  errors={RequestErrors.errors} label="BIC" onChange={changeInputValue} data={UserData.data} name="bank_bic" edit={UserData.edit}/>
						<InputText onFocus={onFocus}  errors={RequestErrors.errors} label="IBAN" onChange={changeInputValue} data={UserData.data} name="bank_iban" edit={UserData.edit}/>

					</div>
					
					</div>

					
				</div>
					
			</div>
			
			
		</div>
	);
}
