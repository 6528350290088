const STORAGE_KEY = "period_set";
const STORAGE_KEY2 = "period_set2";

const STORAGE_KEY_REPORTING_1 = "reporting_period_1";
const STORAGE_KEY_REPORTING_2 = "reporting_period_2";
const d = new Date();
d.setMonth(d.getMonth() - 1);

var report_1=d.getFullYear()+"-"+(d.getMonth()+1)+"-01";
var report_2=d.getFullYear()+"-"+(d.getMonth()+1)+"-01";



if(window.localStorage.getItem(STORAGE_KEY_REPORTING_1) && window.localStorage.getItem(STORAGE_KEY_REPORTING_2)){

	report_1=window.localStorage.getItem(STORAGE_KEY_REPORTING_1);
	report_2=window.localStorage.getItem(STORAGE_KEY_REPORTING_2);

}


const defaultState = {
	
	period:null,
	period2:null,
	reportingperiod1:report_1,
	reportingperiod2:report_2
	
};
export const getDefaultPeriod=(()=>{

	return "2023-01-01";


})();


//alert(window.localStorage.getItem(STORAGE_KEY));
export const uiInitialState = (() => {
	
	defaultState.period=(window.localStorage.getItem(STORAGE_KEY)?window.localStorage.getItem(STORAGE_KEY):getDefaultPeriod);
	defaultState.period2=(window.localStorage.getItem(STORAGE_KEY2)?window.localStorage.getItem(STORAGE_KEY2):getDefaultPeriod);

	return defaultState;


})();

export const uiReducer = (state = uiInitialState, action) => {
	let ui = state;
	switch (action.type) {

		case "CHANGE_REPORTING_PERIOD_1":
			window.localStorage.setItem(STORAGE_KEY_REPORTING_1, action.period);
		
			ui={...ui,reportingperiod1:action.period};

		break;
		case "CHANGE_REPORTING_PERIOD_2":
			
			window.localStorage.setItem(STORAGE_KEY_REPORTING_2, action.period);
		
			ui={...ui,reportingperiod2:action.period};

		break;
		case "CHANGE_PERIOD":
	
			window.localStorage.setItem(STORAGE_KEY, action.period);
		
			ui={...ui,period:action.period};
			
		break;
		case "CHANGE_PERIOD2":
	
			window.localStorage.setItem(STORAGE_KEY2, action.period2);
		
			ui={...ui,period2:action.period2};
			
		break;
		default:
			/*return ui;*/
		break;

	}
    return ui;
};