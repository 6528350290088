import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";

import { config } from "../config";
import InputDouble from "./form/InputDouble.js";

import InputBoolean from "./form/InputBoolean.js";

import Remove from "./form/Remove.js";


import PersonTopNav from "./PersonTopNav.js";
import { apiClient } from "../apiCall";

import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";



  export const defaultUserData={

	loaded:false,
	edit:false,
	data:null,
	orginal:null
	
};


export const DefaultLoanData={
	palkkakausi:"2021-01-01",
	
	laina:0,
	takaisinmaksu:0,
	lyhennys:0


};
export default function PersonBonuses() {
	const { state, dispatch } = useStore();
    let { id } = useParams();
  
    const [UserData, setUserData] = useState({
		...defaultUserData
		
    });

	const apiCall = apiClient(state, dispatch);

	const sortData=(data)=>{


		
		data.settings.sort((a, b) => Date.parse(a.effective_from) < Date.parse(b.effective_from) ? 1 : -1)


		return data;

	};

    useEffect(() => {
		fetch( config.apiUrl+"/persons/"+id,{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data => setUserData({data:sortData(data),orginal:JSON.parse(JSON.stringify(sortData(data))),loaded:true}))

	  }, []);






	
	if(!state.users.items || !state.common.settings){
		return null;
	}

	

	

	if(!UserData.loaded || !state.companies.companies){
        return (<div>Ladataan</div>);
    }
	const changeInputValue = (name,value) => {
	
       setUserData({...UserData,data:{...UserData.data,[name]:value}})



        //dispatch({ type: "LOGINFORM_INPUT", key: name, value: value }, [dispatch]);
	};
	
	const changeEditMode=(e)=>{
		if(!UserData.edit){
			setUserData({...UserData,"edit":true});
		}else{

			let d=JSON.parse(JSON.stringify(UserData.orginal));
			setUserData({...UserData,"edit":false,"data":{...d}});

		}
	
	};

	
	
	


const tryToSave=()=>{

	let url="persons/"+id+"/bonuses";


	/*$table->integer('allow_bonus_from_alaiset')->default(0);
	$table->integer('allow_bonuschart_from_alaiset_total')->default(0);
	$table->integer('allow_bonuschart_from_osastototal')->default(0);
*/
	

	let data={allow_bonus_from_alaiset:UserData.data.allow_bonus_from_alaiset,allow_bonuschart_from_alaiset_total:UserData.data.allow_bonuschart_from_alaiset_total,allow_bonuschart_from_osastototal:UserData.data.allow_bonuschart_from_osastototal,bonusomatalaiset:[...UserData.data.bonusomatalaiset]};
	
	apiCall.ADD(url, data,function(status,err_msgs=null){
	
		if(status==400){

			//setFolderData({...FolderData,errors:err_msgs})
		}else if(status==200){
			setUserData({...UserData,"edit":false});
			apiCall.GET("users");
		}

	}).then(() => {
		//apiCall.GET('destinations');
		
	});




	
};
const handleLoanAdding=(e)=>{

	var loans = [...UserData.data.loans,{...DefaultLoanData,palkkakausi: state.common.current_period}];

		setUserData({...UserData,data:{...UserData.data,loans:loans}})


};
const editLoan=(name,value,index)=>{

	

		let loans=[...UserData.data.loans];
		loans[index]={...loans[index],[name]:value}
		setUserData({...UserData,data:{
			...UserData.data,
			
			loans:loans}})




	



};

const addNewBonus=(target)=>{
	//[...AsetuksetData[target]
	let targetkey="bonusomatalaiset";
	let bonuschart=[{ilmoitettu_myynti_limit:0,bonus:0 },...UserData.data[targetkey]];
	setUserData({...UserData,data:{...UserData.data,bonusomatalaiset:bonuschart}});

  }
const changeBonusValue=(  name,value,index)=>{
    
	let targetkey="bonusomatalaiset";
	let bonuschart=[...UserData.data[targetkey]];
	bonuschart[index][name]=value;

	setUserData({...UserData,data:{...UserData.data,[targetkey]:[...bonuschart]}});
  

   };
const onRemove=(key,index)=>{
	let items=[...UserData.data[key]];
	let bonuschart = items.slice(0, index).concat(items.slice(index + 1, items.length))
	setUserData({...UserData,data:{...UserData.data,[key]:[...bonuschart]}});

};
const bonus= UserData.data.bonusomatalaiset.map((item,index) =>{

	return <tr key={index}><td><InputDouble onChange={changeBonusValue} data={item} index={index} name="ilmoitettu_myynti_limit" edit={UserData.edit}/></td><td><InputDouble onChange={changeBonusValue} data={item} index={index} name="bonus" edit={UserData.edit}/></td><td><Remove onRemove={onRemove}  name="bonusomatalaiset" edit={UserData.edit} index={index} /></td></tr>;
  
  });

const changeValue=(name,value)=>{


	setUserData({...UserData,data:{...UserData.data,[name]:value}});

};

/* $table->integer('allow_bonus_from_alaiset')->default(0);
            $table->integer('allow_bonuschart_from_alaiset_total')->default(0);
            $table->integer('allow_bonuschart_from_osastototal')->default(0);*/ 

	return (

		<div className="person content">




			<PersonTopNav  id={UserData.data.id}/>



				<div className="inner">
				<div className="box">

				<h2>{UserData.data.firstname} {UserData.data.lastname}</h2>

				<div className={"edit-btn "+(UserData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Muokkaa</span></div>

					{UserData.edit?<div className="save-btn" onClick={tryToSave}><span>Tallenna</span></div>:null}


					{UserData.data.role_id==1 ?
						<div>
							<div  className="boolean-row">
								Salli bonus koko osaston myynnistä
								<InputBoolean onChange={changeValue} data={UserData.data}  name="allow_bonuschart_from_osastototal" edit={UserData.edit}/>
								
							</div>
							</div>

						:null}

						{UserData.data.role_id==2 ?
							<div>
							<div  className="boolean-row">
								Salli ryhmä bonus omista alaisista 
								<InputBoolean onChange={changeValue} data={UserData.data}  name="allow_bonuschart_from_alaiset_total" edit={UserData.edit}/>
								
							</div>




							<div  className="boolean-row">
							Salli bonus omista alaisista taulukon mukaan
							<InputBoolean onChange={changeValue} data={UserData.data}  name="allow_bonus_from_alaiset" edit={UserData.edit}/>
								
							</div>
							</div>
							:null}
							



					
					{UserData.data.role_id==2 && UserData.data.allow_bonus_from_alaiset?
					<div className="form-block settings">
						<h3>Bonus taulukko alaisten myynnistä 

						{UserData.edit?
						<div className="edit-btn" onClick={(e) => {
      				  addNewBonus();
   				 }}><span>+</span></div>:null}


						</h3>

					<table className="table"><thead><tr><th>Raja</th><th>Bonus</th><th></th></tr></thead>
      <tbody>{bonus}</tbody>
        </table>
					</div>:null}



					
				</div>
					
			</div>
			
			
		</div>
	);
}
/*<SettingPair name="pohjapalkka" onChange={changeSettingValue} label="Pohjapalkka" defaultSettings={state.common.settings} settings={UserData.data.settings} edit={UserData.edit} />
						<SettingPair name="auto_leasing" onChange={changeSettingValue} label="Autoleasing" defaultSettings={state.common.settings} settings={UserData.data.settings} edit={UserData.edit} />
						<SettingPair name="ulosotto" onChange={changeSettingValue} label="Ulosotto" defaultSettings={state.common.settings} settings={UserData.data.settings} edit={UserData.edit} />
						<SettingPair name="puhelinetu" onChange={changeSettingValue} label="Puhelinetu" defaultSettings={state.common.settings} settings={UserData.data.settings} edit={UserData.edit} />
						<SettingPair name="lounassetelit" onChange={changeSettingValue} label="Lounassetelit" defaultSettings={state.common.settings} settings={UserData.data.settings} edit={UserData.edit} />
						

*/ 