import React,{useState,useEffect} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import {format} from "../commonFunctions";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link,
    Nav,NavLink
	// etc.
  } from "react-router-dom";

export default function SingleBonus(props) {
	const { state, dispatch } = useStore();
  const [open,setOpen]=useState(false);

  const toggleOpen=()=>{

	
    setOpen(!open);
  };

  


  if(props.data.use_seperate==1){//eli per käyttäjä bonus

    const persons= Object.keys(props.data.persons).map((key,index) =>{

        return (<tr key={index} className={(Math.sign(props.data.persons[key].ilmoitettu_myynti)>0?"positive":(Math.sign(props.data.persons[key].ilmoitettu_myynti)<0?"negative":"gray"))} ><td>{props.data.persons[key].name}</td><td  className="amount">{format(props.data.persons[key].ilmoitettu_myynti)} €</td><td  className="amount">{format(props.data.persons[key].bonus)} €</td></tr>:null);
      
      });

      return (
		<div className=" preview">

		    <h4  className={(open?"arrow open":"arrow closed")}  onClick={toggleOpen}>{props.data.topic} <span className="total">{format(props.data.total_bonus)} €</span></h4>
        {open?
			<table className="table">
                <thead>

                    <tr><th>Työntekijä</th><th>Ilmoitettu myynti</th><th>Bonus</th></tr>
                </thead>
                <tbody>

                    {persons}
                   
                    <tr className="total-row"><td>Yhteensä</td><td>{format(props.data.total_ilmoitetttu_myynti)} €</td><td>{format(props.data.total_bonus)} €</td></tr>
                   

                </tbody>

            </table>:null}
			
		</div>
	);





  }else{

    const persons= Object.keys(props.data.persons).map((key,index) =>{

        return (<tr key={index} className={(Math.sign(props.data.persons[key].ilmoitettu_myynti)>0?"positive":(Math.sign(props.data.persons[key].ilmoitettu_myynti)<0?"negative":"gray"))} ><td>{props.data.persons[key].name}</td><td  className="amount">{format(props.data.persons[key].ilmoitettu_myynti)} €</td></tr>:null);
      
      });

      return (
		<div className=" preview">

<h4  className={(open?"arrow open":"arrow closed")} onClick={toggleOpen}>{props.data.topic} <span classNamel="total">{format(props.data.total_bonus)} €</span></h4>
     {open?
			<table className="table">
                <thead>

                    <tr><th>Työntekijä</th><th>Ilmoitettu myynti</th></tr>
                </thead>
                <tbody>

                    {persons}
                   
                    <tr className="total-row"><td>Yhteensä</td><td>{format(props.data.total_ilmoitetttu_myynti)} €</td><td>{format(props.data.total_bonus)} €</td></tr>
                   

                </tbody>

            </table>:null}
			
		</div>
	);



  }
   return <div>ok</div>
    
}
