import React,{useState,useEffect} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import WageBlock from "./form/WageBlock.js";
import WageSheetTable from "./WageSheetTable.js";

import { apiClient } from "../apiCall";
import WageTable from "./form/WageTable.js"
import WageBlockNettoVahennykset from "./form/WageBlockNettoVahennykset.js";
import {format,formatSeason} from "../commonFunctions";


import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link,
    Nav,NavLink
	// etc.
  } from "react-router-dom";

export const defaultData = {
	loading:true,
	simple:"yhteenveto",
	
};
export default function WageDisplay(props) {
	const { state, dispatch } = useStore();

	const apiCall = apiClient(state, dispatch);		

	
	const [Data, setData] = useState({
		...defaultData
		
    });
	useEffect(() => {


		let url="wages/"+props.id+"/confirm";
		///let data={...WageData};

		
		if(props.id!==null){
		apiCall.GET_RAW(url, function(status,err_msgs=null,response=null){

		
			if(status==400){
		
			
			}else if(status==200){
				

			
				setData({...response.data,loading:false,simple:"yhteenveto"});
			}




		});

		}else{
			console.log("PROPS.data",props.data)
			setData({...props.data,loading:false,simple:props.simple});
			
		}
	

	  }, [props.id,props.data]);
	

/*//setWageData({...WageData,"storing":true});
		

		let url="wages/"+data.id+"/preview";
		///let data={...WageData};
		
		apiCall.ADD(url, data,function(status,err_msgs=null,response=null){
				
				
				if(status==400){
		
					//setFolderData({...FolderData,errors:err_msgs})
				}else if(status==200){
					console.log("CALC DATA",response.data);



					setResultData({...ResultData,calculation:{...response.data}});
				}
		
			}).then(() => {
				//apiCall.GET('destinations');
				
			});
		
		
*/ 

console.log("SAATU DATA",Data);



if(Data.loading){
	return (<div className="paywage paywage-set confirm">Ladataan</div>);
	
}	
console.log("DATA KOODI",Data);


if(Data.iszero){

	return (<div>Ei toimintaa kaudella</div>)
}

const rows=Data.palkkalajit.map((item, index) => {

	return <tr className="item" key={index}>
		<td className="koodi">{item.koodi}</td>
		<td>{item.palkkalaji}</td>
		<td>{item.description}</td>
		<td>{item.lmk}</td>
		<td>{format(item.avalue)} €</td>
		<td>{item.kerroin}</td>
		<td>{format(item.total)} €</td>
		</tr>
  
  
});
const hasLisapalkkoja=()=>{
	
	for(var i=0;i<Data.lisatietoja.length;i++){
		let item=Data.lisatietoja[i];
		console.log("DDDD",item.current);
		if(item.current===undefined){
			return false;
		}
		console.log(item);
		if(item.start_of_season!==item.current){
			return true;
		}
	}
	return false;
};

const HAS_LISAPALKKOJA=hasLisapalkkoja();

const rowslisatietoja=Data.lisatietoja.map((item, index) => {

	return <tr className="item" key={index}>
		<td>{item.name}</td>
		{HAS_LISAPALKKOJA&&<td className={(item.strong===true && HAS_LISAPALKKOJA? "strong":null)}>{format(Math.abs(item.current))} €</td>}
		<td className={(item.strong===true && !HAS_LISAPALKKOJA? "strong":null)}>{format(Math.abs(item.season))} €</td>
		<td>{format(item.start_of_season)} €</td>
	
		</tr>
  
  
});

const address_rows=Data.address_rows.map((item, index) => {

	return <span key={index}>
		{item}
	
		</span>
  
  
});
const info_rows=Data.info_rows.map((item, index) => {

	return (item[2]==null?<p key={index}>
		<strong>{item[0]}</strong>
		<span>{item[1]}</span>
		</p>:null);
  
  
});
const formatIban=(v)=>{

	let str="";
	let length=v.length;
	
	for(var i=0;i<length;i++){

		str+=v[i];
		if(i % 4==0 && i!=0){
			str+=" ";
		}
	}
	return str;

};
const info_rows2=Data.info_rows.map((item, index) => {
	let val=item[1];
	if(item[0].toUpperCase()=="IBAN"){
		val=formatIban(val);
		console.log("KUTSUIN",val,item[2]);
	}
	return (item[2]?<p key={index}>
		<strong>{item[0]}</strong>
		<span>{val}</span>
		</p>:null);
  
  
});

const vasen_rows=Data.yhteenveto_vasen.map((item, index) => {

	return <tr key={index}>
		<th>{item.name}</th>
		<td>{format(parseFloat(item.value))}</td>
		</tr>
  
  
});
const vasen_alarows=Data.yhteenveto_alavasen.map((item, index) => {

	return <tr key={index}>
		<th>{item.name}</th>
		<td>{format(item.value)}</td>
		</tr>
  
  
});
const oikea_alarows=Data.yhteenveto_alaoikea.map((item, index) => {

	return <tr key={index}>
		<th>{item.name}</th>
		<td>{format(item.value)}</td>
		</tr>
  
  
});

const oikea_rows=Data.yhteenveto_oikea.map((item, index) => {

	return <tr key={index}>
		<th>{item.name}</th>
		<td>{format(parseFloat(item.value))}</td>
		</tr>
  
  
});


const swap=(mode)=>{

	setData({...Data,simple:mode});

};


console.log("DATANI",Data.laskenta);

return (<div className="wage-display-holder">
{props.id!==null?
	<ul className="palkka-nav">
		<li className={(Data.simple=="yhteenveto"?"active":null)}><span  onClick={()=>swap("yhteenveto")} >Yhteenveto</span></li>
		<li className={(Data.simple=="laskelma"?"active":null)}><span  onClick={()=>swap("laskelma")} >Laskelma</span></li>

		<li  className={(Data.simple=="palkkalaskelma"?"active":null)}><span  onClick={()=>swap("palkkalaskelma")} >Palkkalaskelma</span></li>
	
	{props.print?<li className="li-print"><span onClick={(e)=>{

window.print();
	}}>Tulosta</span></li>:null}
	</ul>
	:null}
	{Data.simple=="laskelma"?
	<div className="print-laskelma">
	<div className="extra-info">
		<h2>{Data.address_rows[0]} {Data.info_rows[0][1]}</h2>
		<strong>Ilmoitettu myynti: </strong>{format(Data.filled.ilmoitettu_myynti)} €<br/>
		<strong>Laskutettu myynti: </strong>{format(Data.filled.laskutettu_myynti)} €

		{(Data.filled.memo!==null && Data.filled.memo!=""?<p className="no-print"><strong>Memo: </strong>{Data.filled.memo}</p>:null)}
	</div>	
	<WageSheetTable hidezeros={true} topic="Laskenta" data={Data.laskenta} total={Data.laskenta_total}/>
	<WageSheetTable hidezeros={true} topic="Kulukorvaukset" data={Data.laskenta_korvaukset} total={Data.laskenta_korvaukset_total}/>

	<WageSheetTable hidezeros={true} topic="Luontoisedut" data={Data.laskenta_luontoisedut} total={Data.laskenta_luontoisedut_total}/>
	<WageSheetTable hidezeros={true} topic="Tulos" data={Data.tulos} total={Data.maksetaan}/></div>

	:null}
	{Data.simple=="yhteenveto"?
	<div>
<table className="yhteenveto-all">
	


{/*}
<WageSheetTable topic="Laskenta" data={Data.laskenta} total={Data.laskenta_total}/>
<WageSheetTable topic="Luontoisedut" data={Data.laskenta_luontoisedut} total={Data.laskenta_luontoisedut_total}/>
  <WageSheetTable topic="Kulukorvaukset" data={Data.laskenta_korvaukset} total={Data.laskenta_korvaukset_total}/>

 <WageSheetTable topic="Tulos" data={Data.calculation.tulos} total={Data.maksetaan}/>
	*/}

	<tr>
		<td>
		<table className="yhteenveto">
				
				<tbody>{vasen_rows}</tbody>

		</table>
		</td>
		<td>
		<table className="yhteenveto">
				
				<tbody>{oikea_rows}</tbody>

		</table>

		</td>
	</tr>
	<tr>
		<td>
				<table className="yhteenveto">
				<tbody>{vasen_alarows}</tbody>

				</table>
		</td>
		<td>
		<table className="yhteenveto">
		<tbody>{oikea_alarows}</tbody>

		</table>
		</td>
	</tr>
	</table></div>:null
}
{Data.simple=="palkkalaskelma"?
	

<div className="palkat">
		<table className="palkkalaskelma palkka-osoite">
		<tr>
			<td colSpan="2" className="address_rows"><div>{address_rows}</div></td>	
			<td colSpan="2" className="info_rows">{info_rows}</td>
			<td colSpan="3" className="bank_info info_rows">{info_rows2}</td>

		</tr>
		<tr className="border"><td colSpan="6"></td></tr>	
	
		<tr className="header-row-palkkalajit"><th>Koodi</th><th>Palkkalaji</th><th>Selite</th><th>Lkm</th><th>á- hinta</th><th>Kerroin</th><th></th></tr>
		
		<tbody className="palkka-rows">{rows}</tbody>
		</table>
		{Data.filled.lisatiedot!==null && Data.filled.lisatiedot!=""?
		<table className="palkkalaskelma lisatiedot fullrow">
		<tr className="header-row-lisatietoja"><td><strong>Lisätietoja:</strong> {Data.filled.lisatiedot}</td></tr>
		</table>:null}
		<table className="palkkalaskelma lisatiedot">
		
	<tbody className="lisatiedot-rows">
	<tr className="header-row-lisatietoja"><th colSpan={HAS_LISAPALKKOJA?4:3}></th>
	</tr>
	<tr className="header-row"><th></th>{HAS_LISAPALKKOJA&&<th>Kausi</th>}<th>Kauden alusta</th><th>Vuoden alusta</th></tr>{rowslisatietoja}</tbody></table>
	<table className="palkkalaskelma palkka-footer">

		<tr className="verotus">
			<th>Verotus</th>
			<td>Vero-%: {Data.taxes.tax_rate} %</td>
			<td>Rajasumma: {Data.taxes.addional_tax_rate_limit} €</td>
			<td>Lisävero-%: {Data.taxes.addional_tax_rate} %</td>
			</tr>

			<tr className="address">
			<td colSpan={4}>{Data.palkka_footer}</td>
			</tr>
	</table>


</div>

:null}<div className="buttons">


{props.store?<button onClick={props.store}>Vahvista ja siirrä maksettaviin</button>:null}

{props.unconfirm?<button onClick={props.unconfirm}>Poista vahvistus</button>:null}

</div>


		</div>);












/*



 
  if(Data.simple==false){




		const keys=[

			
			"autoetu",
			"puhelinetu",
			"lounasetu",

			"tyel",
			"kuukausipalkka",
			"puolipaivarahat",
			"paivarahat",
			"kilometrit",
			"koulutuskilometrikorvaus",
			"koulutuskorvaus",
			"sairasloma",
"lomapalkka","lomakorvaus"

		];

		return (<div><WageTable data={Data} keys={keys}/>
		</div>);

  }else{

 
	return (
		<div className="paywage paywage-set confirm">

			{props.topic?<h2>{props.topic}</h2>:null}
        	<div className="left">
				<WageBlock data={Data} keys={["ilmoitettu_myynti","provisio","ennakkoprovisio","sairasloma","lomapalkka","lomakorvaus"]}/>
				<WageBlock data={Data} keys={["koulutuskilometrikorvaus","koulutuskorvaus","autoetu","puhelinetu","puolipaivarahat","paivarahat"]}/>
				
			</div>
			<div className="right">
			<WageBlock data={Data} keys={["laskutettu_myynti","bonus","bonus_0","pohjapalkka","alaisbonuksetyhteensa"]}/>
			
			
			<div className="props">{additionsandsubs}</div>
			<WageBlock data={Data} keys={["kaikki_yhteensa"]}/>
			<WageBlock data={Data} keys={["lounasetu","duell_laskup","paivarahat","kilometrit","kulukorvaukset_yhteensa"]}/>
			
			
			</div>
			<div className="bottom">
			<div className="left">

			
			<WageBlockNettoVahennykset data={Data.netto_vahennykset} />

			<WageBlock data={Data} keys={["tax_rate","brutto","maksetaan"]}/>


			

				
			
			</div>
			<div className="right">
			<WageBlock data={Data} keys={["addional_tax_rate","nettopalkka","ennakonpidatys"]}/>
			<WageBlock data={Data} keys={["ulosotto","yrityslainat_yhteensa"]}/>

			</div>

			</div>
			<div className="buttons">


					{props.store?<button onClick={props.store}>Vahvista ja siirrä maksettaviin</button>:null}
	</div>
		</div>
	);
}*/ }
