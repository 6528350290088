import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputText from "./form/InputText.js";
import InputSelect from "./form/InputSelect.js";
import InputDouble from "./form/InputDouble.js";

import AdminUser from "./form/AdminUser.js";



import SettingPair from "./form/SettingPair.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";


import TopNav from "./TopNav.js";

import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";



  export const defaultKayttjatData={

	loaded:false,
    data:null,
    showaddform:false
	
	
};

export default function Kayttajahallinta(props) {

    const { state, dispatch } = useStore();


    const [KayttajatData, setKayttjatData] = useState({
		...defaultKayttjatData
		
    });

    const getRole=(admin_role_id=null)=>{

        if(admin_role_id){
            console.log("commoo",state.common.adminroles);

            const found = state.common.adminroles.find((element) => element.id == admin_role_id);

            if(found){
              return found.name;
            }
        }
        return "-";

    };
    const apiResponse=(data)=>{

       
        setKayttjatData({...KayttajatData,data:data,loaded:true,showaddform:false});

    }
    const updateFromserver=()=>{

      var url="/kayttajahallinta";

      fetch( config.apiUrl+url,{headers: new Headers({
        'Authorization': 'Bearer '+state.user.token
        
        })})
        .then((res) => res.json())
        .then(data =>apiResponse(data))

        
    }
	useEffect(() => {
			
	
		
		
    updateFromserver();
	
		
		
	 
		

	  }, []);
    const addNewShowHide=()=>{

      setKayttjatData({...KayttajatData,showaddform:!KayttajatData.showaddform});
    };

      if(KayttajatData.loaded==false || state.common.adminroles==null){
        return null;
      }
     const succesfullyAdded=()=>{

      
        setKayttjatData({...KayttajatData,showaddform:false});
       updateFromserver();
     };
     

      const items= KayttajatData.data.map((item,index) =>{

        return (<tr key={index}><td><Link to={"/kayttajahallinta/"+item.id}>{item.name}</Link></td><td><Link to={"/kayttajahallinta/"+item.id}>{item.email}</Link></td><td><Link to={"/kayttajahallinta/"+item.id}>{getRole(item.adminrole_id)}</Link></td></tr>);
      
      });
      const tableheader=<tr><th>Nimi</th><th>Email</th><th>Rooli</th></tr>;



    return <div>


        <div class="show-quitted" onClick={(e)=>addNewShowHide()}><span>Lisää käyttäjä</span></div>
        {KayttajatData.showaddform?<AdminUser onStore={succesfullyAdded} />:null}
       
        <table><tbody>{tableheader}{items}</tbody></table></div>
    
}
